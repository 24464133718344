import * as React from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { useLocation, useParams, Link } from 'react-router-dom'
import { useState } from 'react';
import { useEffect } from 'react';
import router from '../../../routePaths/routePaths';

export default function BasicBreadcrumbs() {
    const location = useLocation();
    const domain = useParams();
    const [url, setUrl] = useState([]);

    useEffect(()=>{
        let path = location.pathname;
        let pathArr = path.split('/');
        pathArr.shift();
        if(pathArr.length > 2){
            pathArr.pop();
        }
        setUrl(pathArr)
    },[])

    const humanize = (str) => {
        var i, frags = str.split('_');
        for (i=0; i<frags.length; i++) {
          frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
        }
        return frags.join(' ');
    }

    return (
        <div role="presentation">
            <Breadcrumbs aria-label="breadcrumb">
                {url.map((x, index)=>{
                    let path = router[x]+'/'+domain.domain;
                    if(x == 'select_domain'){
                        path = router[x];
                    }else if(x == 'new_rate' || x == '24_hr_rate' || x == 'day_night_weekend_rate'){
                        path = router.update_rate+'/'+domain.domain;
                    }
                    return(
                        <Link to={{pathname: path}} style={{ textDecoration: 'none', color:  (index == (url.length -1) ? '' : 'gray')}}>
                            {humanize(x)} {(x == 'select_domain' &&  domain.domain !== undefined ) ? "( "+domain.domain+" )" : ''}
                        </Link>
                    )
                })}
            </Breadcrumbs>
        </div>
    );
}

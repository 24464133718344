import * as React from 'react';
import {AppBar, Box, Toolbar, Badge, Typography, IconButton, MenuItem, Menu, Modal} from '@mui/material';
import { styled } from '@mui/material/styles';
import MenuIcon from '@mui/icons-material/Menu';
import { PermIdentityOutlined, ShoppingCartOutlined,  } from '@mui/icons-material';

const modelStyle = {
    position: 'absolute',
    top: '1%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '75%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

export default function HeaderView(prop) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  return (
    <>
        <AppBar position="static">
            <Toolbar>
                <div className='row'>
                    <div className="col-8 inline-flex align-items-center">
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            onClick={prop.setOpenMobileNav}
                            edge="start"
                            className="d-lg-none d-xl-none d-md-block d-sm-block d-xs-block"
                        >
                            <MenuIcon />
                        </IconButton>
                    </div>
                    <div className="col-4 d-flex justify-content-end">
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={(e)=>setAnchorEl(e.currentTarget)}
                            color="inherit"
                        >
                            <PermIdentityOutlined />
                        </IconButton>
                    </div>
                </div>
                <Menu
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    open={Boolean(anchorEl)}
                    onClose={()=>setAnchorEl(null)}
                >
                    <MenuItem onClick={prop.logout}>Logout</MenuItem>
                </Menu>
                <Modal
                    backdrop="static"
                    open={prop.openSearchModal}
                    onClose={()=>prop.setCloseSearchModal()}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    disableScrollLock={true}
                    disableEnforceFocus={true}
                    disableAutoFocus={true}
                    disableRestoreFocus={true}
                    sx={{top:'16%'}}
                    BackdropProps= {{
                        classes: {
                            root: "modal-backdrop"
                        }
                    }}
                >
                    <Box sx={modelStyle}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Text in a modal
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
                    </Typography>
                    </Box>
                </Modal>
            </Toolbar>
        </AppBar>
    </>
  );
}

import React, {useState, useEffect} from "react";
import {Button, Paper, Box, Autocomplete, TextField, Typography} from '@mui/material';
import Header from "../Common/Header/Header";
import Sidenav from "../Common/Sidenav/Sidenav";
import commonService from "../../services/common-service";
import Spinner from "../../shared/Spinner";
import SnackAlert from "../../shared/SnackAlert";
import { useParams } from "react-router-dom";

export default function DisableZone() {
    const {domain} = useParams()
    const [installations, setInstallations] = useState([]);
    const [groups, setGroups] = useState([]);
    const [inputField, setInputField] = useState({});
    const [selectedIns, setSelectedIns] = useState(null);
    const [selectedGrp, setSelectedGrp] = useState(null);
    const [spinner, setSpinner] = React.useState(false);
    const [alert, setAlert] = React.useState(false);
    const [msg, setMsg] = React.useState('');
    const [severity, setSeverity] = React.useState('');
    
    const handleChange=(e) => {
        setInputField({...inputField, [e.target.name]: e.target.value});
    }

    const getInstalltions=async() => {
        setSpinner(true);
        const user = JSON.parse(sessionStorage.getItem('userLogged'));
        let body = {domain: domain};
        if(user.email == "cwpadmin"){
            body.ins_description = 'cwp'
        }
        const res = await commonService.get_installtions(body);
        setInstallations(res.data);
        setSpinner(false);
    }

    const onInsSelect=async(newValue) => {
        setSpinner(true);
        setSelectedIns(newValue);
        const res = await commonService.get_groups_by_ins_id({ins_id: newValue.ins_id, domain: domain});
        setGroups(res.data);
        setSpinner(false);
    }

    const handleSubmit=async(e) => {
        e.preventDefault();
        setSpinner(true);
        inputField['grp_id'] = selectedGrp.grp_id
        inputField['domain'] = domain;
        const res = await commonService.disable_zone(inputField);
        console.log(res.data);
        if(res.data == true){
            setMsg("Zone disabled Successfully");
            setSeverity('success');
        }else{
            setMsg("Error occured. Please try again later");
            setSeverity('error');
        }
        setAlert(true);
        setSpinner(false);
    }

    useEffect(()=>{
        getInstalltions();
    },[])

    return (
        <div>
            <Header />
            <div className="main-container my-3">
                <div className="row m-2">
                    <div className="col-lg-2 col-xl-2 d-none d-lg-block d-xl-block">
                        <Sidenav/>
                    </div>
                    <div className="col-lg-10 col-xl-10">
                        <Paper elevation={2} sx={{ width: '100%', p: 3}}>
                            <Typography variant="body1" className="font-bold">Disable zone</Typography>
                            <form onSubmit={handleSubmit}>
                                <div className="row">
                                    <div className='col-lg-6'>
                                        <Autocomplete
                                            disablePortal
                                            id="combo-box-demo"
                                            options={installations}
                                            getOptionLabel={(option) => option.ins_description}
                                            name="selectedIns"
                                            value={selectedIns}
                                            onChange={(event, newValue)=>onInsSelect(newValue)}
                                            renderInput={(params) =>
                                            <TextField 
                                                {...params} id="standard-basic" 
                                                label="City" variant="standard" 
                                                className="width-90" 
                                                required
                                            />}
                                    />
                                    </div>
                                    <div className='col-lg-6'>
                                        <Autocomplete
                                            disablePortal
                                            id="combo-box-demo"
                                            options={groups}
                                            getOptionLabel={(option) => option.GRP_DESCRIPTION}
                                            name="selectedIns"
                                            value={selectedGrp}
                                            onChange={(event, newValue)=>setSelectedGrp(newValue)}
                                            renderInput={(params) =>
                                            <TextField 
                                                {...params} id="standard-basic" 
                                                label="Zone" variant="standard" 
                                                className="width-90" 
                                                required
                                            />}
                                    />
                                    </div>
                                </div>
                                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                                    <Box sx={{ flex: '1 1 auto' }} />
                                    <Button type="button" className="me-2" onClick={()=>{window.location.reload()}}>
                                        Reset
                                    </Button>
                                    <Button type="submit" className="btn btn-blue px-3 fit-content">
                                        Disable Zone
                                    </Button>
                                </Box>
                            </form>
                        </Paper>
                    </div>
                </div>
            </div>
            <Spinner
                spinner = {spinner}
            />
            <SnackAlert 
                msg={msg}
                alert={alert}
                severity={severity}

                closeAlert={()=>setAlert(false)}
            />
        </div>
    );
}

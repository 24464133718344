import React, {useState, useRef, useEffect} from "react";
import {Box, Stepper, Step, StepLabel, Button, Typography, Paper} from '@mui/material';
import GooglePlacesAutocomplete, { geocodeByPlaceId } from 'react-google-places-autocomplete';
import GROUPS from "./GROUPS";
import GROUP_GEOMETRY from "./GROUP_GEOMETRY";
import SnackAlert from "../../shared/SnackAlert";
import Spinner from "../../shared/Spinner";
import ConfirmDiallog from "../../shared/ConfirmDiallog";
import Header from "../Common/Header/Header";
import Sidenav from "../Common/Sidenav/Sidenav";
import QUERY from "./QUERY";
import RATE_STEPS from "./New_Rate/RATE_STEPS";
import commonService from "../../services/common-service";
import { useParams } from "react-router-dom";
import IntegraQUERY from "./IntegraQUERY";
import TicketQUERY from "./TicketQUERY";


const steps = [
    'Add Zone Detail',
    'Insert to dbPIC',
    'Insert to IntegraMobile',
    // 'Insert to Enforcement'
];
export default function Utility() {
    const {id} = useParams()
    const {domain} = useParams()
    const [spinner, setSpinner] = React.useState(false);
    const [alert, setAlert] = React.useState(false);
    const [openDialog, setOpenDialog] = React.useState(false);
    const [msg, setMsg] = React.useState('');
    const [severity, setSeverity] = React.useState('');
    const [integraInsId, setIntegraInsId] = React.useState('');
    const [ticketInsId, setTicketInsId] = React.useState('');
    const [lastQuery, setLastQuery] = useState('');
    const textAreaRef = useRef(null);
    const [activeStep, setActiveStep] = React.useState(0);
    const [center, setCenter] = useState({lat: 52.52549080781086, lng: 13.398118538856465})
    const [lastIds, setLastIds] = useState([]);
    const [installations, setInstallations] = useState([]);
    const [selectedIns, setSelectedIns] = useState(null);
    const [inputGroup, setInputGroup] = useState({});
    const [inputRate, setInputRate] = useState({});
    const [groupQuery, setGroupQuery] = useState('');
    const [integraGroupQuery, setIntegraGroupQuery] = useState('');
    const [ticketGroupQuery, setTicketGroupQuery] = useState('');
    const [rateQuery, setRateQuery] = useState('');
    const [queryObj, setQueryObj] = useState('');
    const [integraQueryObj, setIntegraQueryObj] = useState('');
    const [ticketQueryObj, setTicketQueryObj] = useState('');
    const [geometry, setGeometry] = useState([
        { lat: 52.52549080781086, lng: 13.398118538856465 }
      ]);
    const [geometryQuery, setGeometryQuery] = useState('');
    const [geometryIntegraQuery, setGeometryIntegraQuery] = useState('');
    const [geometryTicketQuery, setGeometryTicketQuery] = useState('');
    
    const handleGroupChange=(e) => {
        setInputGroup({...inputGroup, [e.target.name]: e.target.value});
    }

    const handleRateChange=(e) => {
        setInputRate({...inputRate, [e.target.name]: e.target.value});
    }

    const handleRateChecked=(e) => {
        setInputRate({...inputRate, [e.target.name]: e.target.checked});
    }

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      };
    
    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const query = (val) =>{
        setLastQuery(val);
    }

    const generateQuery = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const copyToClipboard = (e) => {
        textAreaRef.current.select();
        document.execCommand('copy');
        // This is just personal preference.
        // I prefer to not show the whole text area selected.
        setMsg('Copy to Clipboard.')
        setSeverity('success')
        setAlert(true);
    };

    const setAvailableId = () => {
        let obj = {
            grp_id : parseInt(lastIds[0].grp_id)+1,
            TAR_ID : parseInt(lastIds[1].tar_id)+1,
            RAT_ID : parseInt(lastIds[2].rat_id)+1,
            RBS_ID : parseInt(lastIds[3].rbs_id)+1,
            DAT_ID : parseInt(lastIds[4].dat_id)+1,
            DAH_ID : parseInt(lastIds[5].dah_id)+1,
            TCS_ID : parseInt(lastIds[6].tcs_id)+1,
            TCE_ID : parseInt(lastIds[7].tce_id)+1,
          }
          console.log(obj);
        setInputRate(obj);
    }
    useEffect(()=>{
        getInstalltions();
        get_last_ids();
    },[])

    const getInstalltions=async() => {
        const user = JSON.parse(sessionStorage.getItem('userLogged'));
        let body = {domain: domain};
        if(user.email == "cwpadmin"){
            body.ins_description = 'cwp'
        }
        const res = await commonService.get_installtions(body);
        setInstallations(res.data);
    }

    const get_last_ids=async() => {
        setSpinner(true);
        const res = await commonService.get_last_ids({domain: domain});
        setLastIds(res.data);
        inputGroup['grp_id'] = parseInt(res.data[0].grp_id)+1;
        let obj;
        if(id == 'day_night_weekend_rate'){
            obj = {
                grp_id : parseInt(res.data[0].grp_id)+1,
                TAR_ID : parseInt(res.data[1].tar_id)+1,
                Day_RAT_ID : parseInt(res.data[2].rat_id)+1,
                Even_RAT_ID : parseInt(res.data[2].rat_id)+2,
                Weekend_RAT_ID : parseInt(res.data[2].rat_id)+3,
                "12AM_6AM_DAH_ID" : parseInt(res.data[5].dah_id)+1,
                "6AM_6PM_DAH_ID" : parseInt(res.data[5].dah_id)+2,
                "6PM_12PM_DAH_ID" : parseInt(res.data[5].dah_id)+3,
                Day_DAT_ID : parseInt(res.data[4].dat_id)+1,
                Even_DAT_ID : parseInt(res.data[4].dat_id)+2,
                Weekend_DAT_ID : parseInt(res.data[4].dat_id)+3,
                RBS_ID : parseInt(res.data[3].rbs_id)+1,
                Day_TCS_ID : parseInt(res.data[6].tcs_id)+1,
                Even_TCS_ID : parseInt(res.data[6].tcs_id)+2,
                Weekend_TCS_ID : parseInt(res.data[6].tcs_id)+3,
                Day_TCE_ID : parseInt(res.data[7].tce_id)+1,
                Even_TCE_ID : parseInt(res.data[7].tce_id)+2,
                Weekend_TCE_ID : parseInt(res.data[7].tce_id)+3,
                integra_lit_id : parseInt(res.data[8].integra_lit_id)+1,
                integra_grp_id : parseInt(res.data[9].integra_grp_id)+1,
                integra_tar_id : parseInt(res.data[10].integra_tar_id)+1,
                integra_targr_id : parseInt(res.data[11].integra_tar_id)+1,
                // ticket_str_id : parseInt(res.data[13].ticket_str_id)+1,
                // ticket_grp_id : parseInt(res.data[14].ticket_grp_id)+1,
                // ticket_grge_id : parseInt(res.data[15].ticket_grge_id)+1,
                // ticket_grhi_id : parseInt(res.data[16].ticket_grhi_id)+1,
              }
        }else if(id == 'overlapped_rate'){
            obj = {
                grp_id : parseInt(res.data[0].grp_id)+1,
                TAR_ID : parseInt(res.data[1].tar_id)+1,
                Day_RAT_ID : parseInt(res.data[2].rat_id)+1,
                Even_RAT_ID : parseInt(res.data[2].rat_id)+2,
                Weekend_RAT_ID : parseInt(res.data[2].rat_id)+3,
                "12AM_6AM_DAH_ID" : parseInt(res.data[5].dah_id)+1,
                "6AM_6PM_DAH_ID" : parseInt(res.data[5].dah_id)+2,
                "5PM_12PM_DAH_ID" : parseInt(res.data[5].dah_id)+3,
                "6PM_12PM_DAH_ID" : parseInt(res.data[5].dah_id)+4,
                "6AM_5PM_DAH_ID" : parseInt(res.data[5].dah_id)+5,
                Day_DAT_ID : parseInt(res.data[4].dat_id)+1,
                Even_DAT_ID : parseInt(res.data[4].dat_id)+2,
                Weekend_DAT_ID : parseInt(res.data[4].dat_id)+3,
                RBS_ID : parseInt(res.data[3].rbs_id)+1,
                Day_TCS_ID : parseInt(res.data[6].tcs_id)+1,
                Even_TCS_ID : parseInt(res.data[6].tcs_id)+2,
                Weekend_TCS_ID : parseInt(res.data[6].tcs_id)+3,
                Day_TCE_ID : parseInt(res.data[7].tce_id)+1,
                Even_TCE_ID : parseInt(res.data[7].tce_id)+2,
                Weekend_TCE_ID : parseInt(res.data[7].tce_id)+3,
                integra_lit_id : parseInt(res.data[8].integra_lit_id)+1,
                integra_grp_id : parseInt(res.data[9].integra_grp_id)+1,
                integra_tar_id : parseInt(res.data[10].integra_tar_id)+1,
                integra_targr_id : parseInt(res.data[11].integra_tar_id)+1,
                // ticket_str_id : parseInt(res.data[13].ticket_str_id)+1,
                // ticket_grp_id : parseInt(res.data[14].ticket_grp_id)+1,
                // ticket_grge_id : parseInt(res.data[15].ticket_grge_id)+1,
                // ticket_grhi_id : parseInt(res.data[16].ticket_grhi_id)+1,
              }
        }else if(id == '24_hr_rate'){
            obj = {
                grp_id : parseInt(res.data[0].grp_id)+1,
                TAR_ID : parseInt(res.data[1].tar_id)+1,
                RAT_ID : parseInt(res.data[2].rat_id)+1,
                RBS_ID : parseInt(res.data[3].rbs_id)+1,
                DAT_ID : parseInt(res.data[4].dat_id)+1,
                DAH_ID : parseInt(res.data[5].dah_id)+1,
                TCS_ID : parseInt(res.data[6].tcs_id)+1,
                TCE_ID : parseInt(res.data[7].tce_id)+1,
                integra_lit_id : parseInt(res.data[8].integra_lit_id)+1,
                integra_grp_id : parseInt(res.data[9].integra_grp_id)+1,
                integra_tar_id : parseInt(res.data[10].integra_tar_id)+1,
                integra_targr_id : parseInt(res.data[11].integra_targr_id)+1,
                // ticket_str_id : parseInt(res.data[13].ticket_str_id)+1,
                // ticket_grp_id : parseInt(res.data[14].ticket_grp_id)+1,
                // ticket_grge_id : parseInt(res.data[15].ticket_grge_id)+1,
                // ticket_grhi_id : parseInt(res.data[16].ticket_grhi_id)+1,
              }
        }
        setInputRate(obj);
        setSpinner(false);
    }

    const onInsSelect=async (newValue)=>{
        setSelectedIns(newValue);
        inputGroup['ins_id'] = newValue.ins_id;
        inputGroup['grp_id'] = inputRate.grp_id;
        setSpinner(true);
        const res = await commonService.get_integra_ins_id({ins_id : newValue.ins_id, domain: domain});
        // const resTicket = await commonService.get_ticket_ins_id({ins_id : newValue.ins_id, domain: domain});
        // console.log(res.data,resTicket.data)
        setIntegraInsId(res.data[0].ins_id);
        // setTicketInsId(resTicket.data[0].INS_ID);
        setInputRate({...inputRate, integra_ins_id: parseInt(res.data[0].ins_id)});
        // inputRate['integra_ins_id'] = parseInt(res.data[0].ins_id)
        // inputRate['ticket_ins_id'] = parseInt(resTicket.data[0].INS_ID)
        setSpinner(false);
        console.log(inputRate)
    }

    return (
        <div>
            <Header />
            <div className="main-container my-3">
                <div className="row m-2">
                    <div className="col-lg-2 col-xl-2 d-none d-lg-block d-xl-block">
                        <Sidenav/>
                    </div>
                    <div className="col-lg-10 col-xl-10">
                        <Paper elevation={2} sx={{ width: '100%', pt: 2}}>
                            <Stepper activeStep={activeStep}>
                                {steps.map((label, index) => {
                                const stepProps = {};
                                const labelProps = {};
                                return (
                                    <Step key={label} {...stepProps}>
                                    <StepLabel {...labelProps}>{label}</StepLabel>
                                    </Step>
                                );
                                })}
                            </Stepper>
                            {activeStep === steps.length ? (
                                <React.Fragment>
                                <Typography sx={{ mt: 2, mb: 1 }}>
                                    All steps completed - you&apos;re finished
                                </Typography>
                                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                                    <Box sx={{ flex: '1 1 auto' }} />
                                    <Button >Reset</Button>
                                </Box>
                                </React.Fragment>
                            ) : (
                                <React.Fragment>
                                    {activeStep === 0 && 
                                        <>
                                        <GROUPS
                                            inputGroup = {inputGroup}
                                            inputRate = {inputRate}
                                            installations  = {installations}
                                            selectedIns = {selectedIns}
                                            integraInsId = {integraInsId}
                                            ticketInsId = {ticketInsId}

                                            handleGroupChange = {(e)=>handleGroupChange(e)}
                                            query = {(value)=>{query(value)}}
                                            setInputGroup = {(e)=>setInputGroup(e)}
                                            setGroupQuery = {(e)=>setGroupQuery(e)}
                                            setIntegraGroupQuery = {(e)=>setIntegraGroupQuery(e)}
                                            setTicketGroupQuery = {(e)=>setTicketGroupQuery(e)}
                                            onInsSelect = {(e)=>onInsSelect(e)}
                                            handleNext = {()=>handleNext()}
                                        />
                                        <Typography variant="h6" className="font-bold m-3 mb-5">Select Group Geometry</Typography>
                                        <div className="row" style={{paddingBottom: "4rem"}}>
                                            <GROUP_GEOMETRY
                                                googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyDcPvfjCIqeUpGe8_IaI_-5rIZX9qtCUnI&libraries=drawing,places"
                                                loadingElement={<div style={{ height: `100%` }} />}
                                                containerElement={<div style={{ height: `55vh` }} />}
                                                mapElement={<div style={{ height: `100%` }} />}
                                                center={center}
                                                zoom={16}
                                                geometry={geometry}
                                                grp_id={inputGroup.grp_id}
                                                integra_grp_id={inputRate.integra_grp_id}
                                                ticket_grp_id={inputRate.ticket_grp_id}
                                                ticket_grge_id={inputRate.ticket_grge_id}

                                                setCenter={(value)=>{setCenter(value)}}
                                                setGeometry={(value)=>{setGeometry(value)}}
                                                removePolygon={(value)=>{setGeometry([])}}
                                                setGeometryQuery={(value)=>{setGeometryQuery(value)}}
                                                setGeometryIntegraQuery ={(value)=>{setGeometryIntegraQuery(value)}}
                                                setGeometryTicketQuery ={(value)=>{setGeometryTicketQuery(value)}}
                                                handleBack = {()=>handleBack()}
                                                handleNext = {()=>handleNext()}
                                            />
                                        </div>
                                        <RATE_STEPS
                                            inputRate = {inputRate}
                                            inputGroup = {inputGroup}
                                            lastIds = {lastIds}
                                            integraInsId = {integraInsId}
                                            ticketInsId = {ticketInsId}
                                            id = {id}

                                            handleRateChange = {(e)=>handleRateChange(e)}
                                            handleRateChecked = {(e)=>handleRateChecked(e)}
                                            setInputRate = {(e)=>setInputRate(e)}
                                            setRateQuery = {(e)=>setRateQuery(e)}
                                            setQueryObj = {(e)=>setQueryObj(e)}
                                            setIntegraQueryObj = {(e)=>setIntegraQueryObj(e)}
                                            setTicketQueryObj = {(e)=>setTicketQueryObj(e)}
                                            setGroupQuery = {(e)=>setGroupQuery(e)}
                                            setIntegraGroupQuery = {(e)=>setIntegraGroupQuery(e)}
                                            setTicketGroupQuery = {(e)=>setTicketGroupQuery(e)}
                                            generateQuery = {()=>generateQuery()}
                                            setAvailableId = {(e)=>setAvailableId(e)}
                                            handleBack = {()=>handleBack()}
                                        />
                                        </>
                                    }
                                    {activeStep === 1 && 
                                        <QUERY
                                            lastQuery = {lastQuery}
                                            textAreaRef = {textAreaRef}
                                            inputGroup = {inputGroup}
                                            groupQuery = {groupQuery}
                                            integraGroupQuery = {integraGroupQuery}
                                            ticketGroupQuery = {ticketGroupQuery}
                                            geometryQuery = {geometryQuery} 
                                            geometryIntegraQuery = {geometryIntegraQuery}
                                            geometryTicketQuery = {geometryTicketQuery}
                                            rateQuery = {rateQuery}
                                            queryObj = {queryObj}
                                            integraQueryObj = {integraQueryObj}
                                            ticketQueryObj = {ticketQueryObj}

                                            handleBack = {()=>handleBack()}
                                            copyToClipboard = {()=>{copyToClipboard()}}
                                            handleNext = {()=>handleNext()}
                                        />
                                    }
                                    {activeStep === 2 && 
                                        <IntegraQUERY
                                            lastQuery = {lastQuery}
                                            textAreaRef = {textAreaRef}
                                            inputGroup = {inputGroup}
                                            groupQuery = {groupQuery}
                                            integraGroupQuery = {integraGroupQuery}
                                            ticketGroupQuery = {ticketGroupQuery}
                                            geometryQuery = {geometryQuery} 
                                            geometryIntegraQuery = {geometryIntegraQuery}
                                            geometryTicketQuery = {geometryTicketQuery}
                                            rateQuery = {rateQuery}
                                            queryObj = {queryObj}
                                            integraQueryObj = {integraQueryObj}
                                            ticketQueryObj = {ticketQueryObj}

                                            handleBack = {()=>handleBack()}
                                            copyToClipboard = {()=>{copyToClipboard()}}
                                            handleNext = {()=>handleNext()}
                                        />
                                    }
                                    {activeStep === 3 && 
                                        <TicketQUERY
                                            lastQuery = {lastQuery}
                                            textAreaRef = {textAreaRef}
                                            inputGroup = {inputGroup}
                                            groupQuery = {groupQuery}
                                            integraGroupQuery = {integraGroupQuery}
                                            ticketGroupQuery = {ticketGroupQuery}
                                            geometryQuery = {geometryQuery} 
                                            geometryIntegraQuery = {geometryIntegraQuery}
                                            geometryTicketQuery = {geometryTicketQuery}
                                            rateQuery = {rateQuery}
                                            queryObj = {queryObj}
                                            integraQueryObj = {integraQueryObj}
                                            ticketQueryObj = {ticketQueryObj}

                                            handleBack = {()=>handleBack()}
                                            copyToClipboard = {()=>{copyToClipboard()}}
                                        />
                                    }

                                </React.Fragment>
                            )}
                        </Paper>
                    </div>
                </div>
            </div>
            
            <Spinner
                spinner = {spinner}
            />
            <SnackAlert 
                msg={msg}
                alert={alert}
                severity={severity}

                closeAlert={()=>setAlert(false)}
            />
            <ConfirmDiallog
                openDialog = {openDialog}

                closeDialog = {()=>setOpenDialog(false)}
            />
        </div>
    );
}

import React, {useState, useEffect} from "react";
import {Button, Paper, Box, Autocomplete, TextField} from '@mui/material';
import Header from "../../Common/Header/Header";
import Sidenav from "../../Common/Sidenav/Sidenav";
import commonService from "../../../services/common-service";
import SnackAlert from "../../../shared/SnackAlert";
import Spinner from "../../../shared/Spinner";
import ExternalPermitForm from "./ExternalPermitForm";
import permitQueryGenerator from "../../../helper/permit_query_generator";
import { useParams } from "react-router-dom";

export default function ExternalPermit() {
    const {domain} = useParams();
    const [installations, setInstallations] = useState([]);
    const [groups, setGroups] = useState([]);
    const [permits, setPermits] = useState([]);
    const [grpType, setGrpType] = useState({});
    const [inputField, setInputField] = useState({});
    const [selectedIns, setSelectedIns] = useState(null);
    const [selectedGrp, setSelectedGrp] = useState(null);
    const [spinner, setSpinner] = React.useState(false);
    const [alert, setAlert] = React.useState(false);
    const [msg, setMsg] = React.useState('');
    const [severity, setSeverity] = React.useState('');
    
    const handleChange=(e) => {
        setInputField({...inputField, [e.target.name]: e.target.value});
    }

    const getInstalltions=async() => {
        setSpinner(true);
        const user = JSON.parse(sessionStorage.getItem('userLogged'));
        let body = {domain: domain};
        if(user.email == "cwpadmin"){
            body.ins_description = 'cwp'
        }
        const res = await commonService.get_installtions(body);
        setInstallations(res.data);
        setSpinner(false);
    }

    const get_last_ids=async() => {
        setSpinner(true);
        const res = await commonService.get_last_ids({domain: domain});
        let obj = {
            TAR_ID : parseInt(res.data[1].tar_id)+1,
            TCS_ID : parseInt(res.data[6].tcs_id)+1,
            grpt_id: parseInt(res.data[12].grpt_id)+1
        }
        setInputField(obj);
        setSpinner(false);
    }

    const onInsSelect=async(newValue) => {
        setSpinner(true);
        setSelectedIns(newValue);
        const res = await commonService.get_groups_by_ins_id({ins_id: newValue.ins_id, domain: domain});
        const grp_type = await commonService.get_grpt_by_ins_id({ins_id: newValue.ins_id, domain: domain});
        console.log(grp_type.data[grp_type.data[grp_type.data.length-1]])
        setGrpType(grp_type.data[grp_type.data.length-1]);
        setSelectedGrp(null);
        setGroups(res.data);
        setPermits([])
        setSpinner(false);
    }

    const onGrpSelect=async(newValue) => {
        setSpinner(true);
        setSelectedGrp(newValue);
        console.log(grpType);
        if(grpType !== undefined){
            const res = await commonService.get_monthly_permit_by_grp_id({grp_id: newValue.grp_id, grpt_id: grpType.GRPT_ID, domain: domain});
            setPermits(res.data)
        }
        setSpinner(false);
    }

    const handleSubmit=async(e) => {
        e.preventDefault();
        setSpinner(true);
        inputField['grp_id'] = selectedGrp.grp_id
        inputField['ins_id'] = selectedIns.ins_id
        let queryObj;
        if(permits.length > 0){
            console.log(permits)
            inputField['grpt_id'] = grpType.GRPT_ID
            queryObj = permitQueryGenerator.newExistedPermit(inputField)
        }else{
            queryObj = permitQueryGenerator.newExternalPermit(inputField)
        }
        Object.keys(queryObj).map(function(key, index) {
            queryObj[key] = queryObj[key].replace(/\n/g, '');
          });
        queryObj['domain'] = domain;
        const res = await commonService.insert_to_db(queryObj);
        if(res.data == true){
            setMsg("External Permit added successfully");
            setSeverity('success');
            setPermits([]);
            setSelectedGrp(null);
        }else{
            setMsg(res.data.map(x=>{return x}).join('\n\n'))
            setSeverity('error');
        }
        setAlert(true);
        setSpinner(false);
    }

    useEffect(()=>{
        getInstalltions();
        get_last_ids();
    },[])

    return (
        <div>
            <Header />
            <div className="main-container my-3">
                <div className="row m-2">
                    <div className="col-lg-2 col-xl-2 d-none d-lg-block d-xl-block">
                        <Sidenav/>
                    </div>
                    <div className="col-lg-10 col-xl-10">
                        <Paper elevation={2} sx={{ width: '100%', p: 3}}>
                            <ExternalPermitForm
                                installations = {installations}
                                groups = {groups}
                                selectedIns = {selectedIns}
                                selectedGrp = {selectedGrp}
                                inputField = {inputField}
                                permits = {permits}

                                onInsSelect = {(e)=>onInsSelect(e)}
                                onGrpSelect = {(e)=>onGrpSelect(e)}
                                handleChange = {(e)=>handleChange(e)}
                                handleSubmit = {(e)=>handleSubmit(e)}
                            />
                        </Paper>
                    </div>
                </div>
            </div>
            <Spinner
                spinner = {spinner}
            />
            <SnackAlert 
                msg={msg}
                alert={alert}
                severity={severity}

                closeAlert={()=>setAlert(false)}
            />
        </div>
    );
}

import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import {Button, Typography, Paper, Box} from '@mui/material';
import Header from "../Common/Header/Header";
import Sidenav from "../Common/Sidenav/Sidenav";
import router from "../../routePaths/routePaths";

export default function SelectRate() {
    let navigate = useNavigate();
    let {domain} = useParams();

    return (
        <div>
            <Header />
            <div className="main-container my-3">
                <div className="row m-2">
                    <div className="col-lg-2 col-xl-2 d-none d-lg-block d-xl-block">
                        <Sidenav/>
                    </div>
                    <div className="col-lg-10 col-xl-10">
                        <Paper elevation={2} sx={{ width: '100%', p: 3}}>
                            <div classNmae="row">
                                <Typography>
                                    Please select rate type
                                </Typography>
                            </div>
                            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                                <Button className="btn btn-blue px-3 mx-2 fit-content" onClick={()=>navigate(router.new_rate+'/24_hr_rate'+'/'+domain)}>
                                    Add 24hr rate
                                </Button>
                                <Button className="btn btn-blue px-3 mx-2 fit-content" onClick={()=>navigate(router.new_rate+'/day_night_weekend_rate'+'/'+domain)}>
                                    Add Day, Night and Weekend rate
                                </Button>
                                <Button className="btn btn-blue px-3 mx-2 fit-content" onClick={()=>navigate(router.new_rate+'/overlapped_rate'+'/'+domain)}>
                                    Add Overlapped rate
                                </Button>
                                <Button className="btn btn-blue px-3 mx-2 fit-content" onClick={()=>navigate(router.update_rate+'/'+domain)}>
                                    Update rate
                                </Button>
                            </Box>
                            <div className="row">
                            </div>
                        </Paper>
                    </div>
                </div>
            </div>
        </div>
    );
}

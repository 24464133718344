import React, {useState, useEffect} from "react";
import { 
    Button, Typography, Paper, TextareaAutosize, Box
} from "@mui/material";
import commonService from "../../services/common-service";
import SnackAlert from "../../shared/SnackAlert";
import Spinner from "../../shared/Spinner";
import { useParams } from "react-router-dom";

export default function TicketQUERY(props) {
    const {domain} = useParams();
    const [spinner, setSpinner] = React.useState(false);
    const [alert, setAlert] = React.useState(false);
    const [msg, setMsg] = React.useState('');
    const [severity, setSeverity] = React.useState('');
    const [ticketQueryObj, setTicketQueryObj] = useState({});

    useEffect(() => {
        let ticketObj = {};
        ticketObj['group'] = props.ticketGroupQuery
        ticketObj['group_geometry'] = props.geometryTicketQuery;
        setTicketQueryObj({...ticketObj, ...props.ticketQueryObj});
      }, []);

    const handleTicketChange=(e) => {
        setTicketQueryObj({...ticketQueryObj, [e.target.name]: e.target.value});
    }
    
    const insertToDB = async () =>{
        setSpinner(true);
        // Object.keys(ticketQueryObj).map(function(key, index) {
        //     ticketQueryObj[key] = ticketQueryObj[key].replace(/\n/g, '');
        // });
        // ticketQueryObj['domain'] = domain;
        let arr = [];
        Object.keys(ticketQueryObj).map(function(key, index) {
            let tempArray = ticketQueryObj[key].replace(/\n/g, '').split(";");
            arr = arr.concat(tempArray.filter(function (el) {return el != "";}))
        });
        let body = {
            domain: domain,
            query: arr
        }
        const res = await commonService.insert_to_ticket(body);
        if(res.data == true){
            setMsg("Zone Added Successfully");
            setSeverity('success');
        }else{
            let result = res.data.split('</div>');
            let errArray = JSON.parse(result[result.length-1].replace('\n',''));
            setMsg(errArray.map(x=>{return x}).join('\n\n'))
            setSeverity('error');
        }
        setAlert(true);
        setSpinner(false);
    }
    return (
        <div className="row">
            <Paper elevation={0} sx={{whiteSpace: 'pre-wrap', mt: 3, p: 2}}>
                <div className="row">
                    <Typography variant="h5" className="font-bold">Enforcement</Typography>
                    <div className="col-md-12">
                        <Typography variant="body1" className="font-bold">Streets & Groups</Typography>
                        <TextareaAutosize
                            aria-label="minimum height"
                            minRows={2}
                            name="group"
                            style={{ width: "100%", marginTop: '13px !important', border: 0, background: '#f0f0f0', padding: '15px'}}
                            value={ticketQueryObj['group']}
                            onChange={(e)=>handleTicketChange(e)}
                            ref={props.textAreaRef}
                        />
                    </div>
                    <div className="col-md-12">
                        <Typography variant="body1" className="font-bold">Group Geometry</Typography>
                        <TextareaAutosize
                            aria-label="minimum height"
                            minRows={2}
                            name="group_geometry"
                            style={{ width: "100%", marginTop: '13px !important', border: 0, background: '#f0f0f0', padding: '15px'}}
                            value={ticketQueryObj['group_geometry']}
                            onChange={(e)=>handleTicketChange(e)}
                            ref={props.textAreaRef}
                        />
                    </div>
                    <div className="col-md-12">
                        <Typography variant="body1" className="font-bold">GROUPS HIERARCHY</Typography>
                        <TextareaAutosize
                            aria-label="minimum height"
                            minRows={2}
                            name="ticket_grhi"
                            style={{ width: "100%", marginTop: '13px !important', border: 0, background: '#f0f0f0', padding: '15px'}}
                            value={ticketQueryObj['ticket_grhi']}
                            onChange={(e)=>handleTicketChange(e)}
                            ref={props.textAreaRef}
                        />
                    </div>
                </div>
            </Paper>
            <Box sx={{ display: 'flex', flexDirection: 'row', py: 2 }}>
                <Button
                    color="inherit"
                    onClick={()=>props.handleBack()}
                    sx={{ mr: 1 }}
                    >
                    Back
                </Button>
                <Box sx={{ flex: '1 1 auto' }} />
                <Button type="button" className="me-2" onClick={()=>{window.location.reload()}}>
                    Reset
                </Button>
                <Button type="button" className="btn btn-blue px-3 fit-content" onClick={()=>{insertToDB()}}>
                    Insert to Enforcement
                </Button>
            </Box>
            <Spinner
                spinner = {spinner}
            />
            <SnackAlert 
                msg={msg}
                alert={alert}
                severity={severity}

                closeAlert={()=>setAlert(false)}
            />
        </div>
    );
}

import React from "react";
import { 
    Button, Typography, Paper, TextareaAutosize, Box
} from "@mui/material";

export default function NewPermitIntegraQuery(props) {
    
    return (
        <div className="row">
            <Paper elevation={0} sx={{whiteSpace: 'pre-wrap', mt: 3, p: 2}}>
                <div className="row">
                    <Typography variant="h5" className="font-bold">IntegraMobile</Typography>
                    <div className="col-md-12">
                        <Typography variant="body1" className="font-bold">Literal</Typography>
                        <TextareaAutosize
                            aria-label="minimum height"
                            minRows={2}
                            name="literals"
                            style={{ width: "100%", marginTop: '13px !important', border: 0, background: '#f0f0f0', padding: '15px'}}
                            value={props.inputIntegraQuery['literals']}
                            onChange={(e)=>props.handleIntegraQueryChange(e)}
                            ref={props.textAreaRef}
                        />
                    </div>
                    <div className="col-md-12">
                        <Typography variant="body1" className="font-bold">Tariff</Typography>
                        <TextareaAutosize
                            aria-label="minimum height"
                            minRows={2}
                            name="integra_tarif"
                            style={{ width: "100%", marginTop: '13px !important', border: 0, background: '#f0f0f0', padding: '15px'}}
                            value={props.inputIntegraQuery['integra_tarif']}
                            onChange={(e)=>props.handleIntegraQueryChange(e)}
                            ref={props.textAreaRef}
                        />
                    </div>
                    <div className="col-md-12">
                        <Typography variant="body1" className="font-bold">Tariff In Group</Typography>
                        <TextareaAutosize
                            aria-label="minimum height"
                            minRows={2}
                            name="integra_tarif_in_group"
                            style={{ width: "100%", marginTop: '13px !important', border: 0, background: '#f0f0f0', padding: '15px'}}
                            value={props.inputIntegraQuery['integra_tarif_in_group']}
                            onChange={(e)=>props.handleIntegraQueryChange(e)}
                            ref={props.textAreaRef}
                        />
                    </div>
                </div>
            </Paper>
            <Box sx={{ display: 'flex', flexDirection: 'row', py: 2 }}>
                <Button
                    color="inherit"
                    onClick={()=>props.handleBack()}
                    sx={{ mr: 1 }}
                    >
                    Back
                </Button>
                <Box sx={{ flex: '1 1 auto' }} />
                <Button type="button" className="me-2" onClick={()=>{window.location.reload()}}>
                    Reset
                </Button>
                <Button type="button" className="btn btn-blue px-3 fit-content" onClick={props.insertToIntegra}>
                    Insert to IntegraMobile
                </Button>
            </Box>
        </div>
    );
}

const prod = {
    url: {
        API_URL: 'https://blinkay.ca/gen-script-api/index.php/' ,
        clientId: '824275899840-4q1s5024mel8fltl8m54pgs96rjsq3ut.apps.googleusercontent.com'
    }
};
const dev = {
    url: {
        API_URL: 'https://blinkay.ca/gen-script-api/index.php/' ,
        // API_URL: 'http://localhost:1234/gen-script-api/index.php/' ,
        clientId: '824275899840-4q1s5024mel8fltl8m54pgs96rjsq3ut.apps.googleusercontent.com'
    }
};

export const config = process.env.NODE_ENV === "development" ? dev : prod;
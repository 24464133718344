import React from "react";
import {
    withScriptjs,
    withGoogleMap,
    GoogleMap,
    Polygon,
  } from "react-google-maps";

function Geometry(props) {

    return (
        <>
            {props.polygon.length > 0 && <GoogleMap
                defaultZoom={17}
                center={props.polygon[0]}
                >
                <Polygon
                    path={props.polygon}
                    options={{
                    strokeColor: "#032d6f",
                    strokeOpacity: 0.8,
                    strokeWeight: 2,
                    fillColor: "#032d6f",
                    fillOpacity: 0.35
                    }}
                />
            </GoogleMap>}
        </>
    );
}

export default withScriptjs(withGoogleMap(Geometry));

import * as React from 'react';
import { Link } from 'react-router-dom';
import {
  Box, Paper, Avatar, Typography, Divider, List, 
  ListItemButton, IconButton, ListItemText, ListItemIcon,
} from '@mui/material';
import { 
  AccountBoxOutlined, 
  BrandingWatermarkOutlined, 
  Close, 
  DashboardOutlined, 
  Inventory2Outlined,
  ShoppingCartOutlined,
  Category
   
} from '@mui/icons-material';
import logo from "../../../assets/images/logo/logo.png"
import router from '../../../routePaths/routePaths';

export default function Sidenav(prop) {
  const [open, setOpen] = React.useState(true);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <Box
      sx={{
        width: '100%',
        height: '90vh',
        mt: '-37px'
      }}
    >
      <Paper elevation={3} sx={{height: '100%', p:2}} className="text-center bg-blue">
        <div className='text-end d-lg-none d-xl-none d-md-block d-sm-block d-xs-block'>
          <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={prop.setOpenMobileNav}
              edge="start"
          >
              <Close className="text-white"/>
          </IconButton>
        </div>
        <div style={{justifyContent: "center", display: "flex" }}>
          <Avatar alt="Remy Sharp" className="mt-4 mb-2" src={logo} sx={{ width: 80, height: 80}}/>
        </div>
        {/* <Typography variant="caption" className="font-gold font-bold" > Update profile</Typography> */}
        <Divider className='font-grey my-1'/>
        <List
          sx={{ width: '100%', maxWidth: '100%', bgcolor: 'transparent' }}
          component="nav"
          aria-labelledby="nested-list-subheader"
        >
          <Paper className="bg-light-blue">
            <ListItemButton onClick={handleClick} className="px-2">
              <Link to={{pathname: router.select_domain}} style={{ textDecoration: 'none', display: 'flex' }}>
                <ListItemIcon sx={{minWidth: '40px'}}>
                  <DashboardOutlined sx={{color: 'white'}}/>
                </ListItemIcon>
                <ListItemText className="font-500 font-white font-subheading" disableTypography >
                  Dashboard
                </ListItemText>
              </Link>
            </ListItemButton>
            {/* <ListItemButton onClick={handleClick} className="px-2">
              <Link to={{pathname: router.adminProfile}} style={{ textDecoration: 'none', display: 'flex' }}>
                <ListItemIcon sx={{minWidth: '40px'}}>
                  <AccountBoxOutlined sx={{color: 'white'}}/>
                </ListItemIcon>
                <ListItemText className="font-500 text-white font-subheading" disableTypography >
                  Profile
                </ListItemText>
              </Link>
            </ListItemButton>
            <ListItemButton onClick={handleClick} className="px-2">
              <Link to={{pathname: router.adminCollections}} style={{ textDecoration: 'none', display: 'flex' }}>
                <ListItemIcon sx={{minWidth: '40px'}}>
                  <Category sx={{color: 'white'}}/>
                </ListItemIcon>
                <ListItemText className="font-500 text-white font-subheading" disableTypography >
                  Collections
                </ListItemText>
              </Link>
            </ListItemButton>
            <ListItemButton onClick={handleClick} className="px-2">
              <Link to={{pathname: router.adminBrands}} style={{ textDecoration: 'none', display: 'flex' }}>
                <ListItemIcon sx={{minWidth: '40px'}}>
                  <BrandingWatermarkOutlined sx={{color: 'white'}}/>
                </ListItemIcon>
                <ListItemText className="font-500 text-white font-subheading" disableTypography >
                  Brands
                </ListItemText>
              </Link>
            </ListItemButton>
            <ListItemButton onClick={handleClick} className="px-2">
              <Link to={{pathname: router.adminProducts}} style={{ textDecoration: 'none', display: 'flex' }}>
                <ListItemIcon sx={{minWidth: '40px'}}>
                  <Inventory2Outlined sx={{color: 'white'}}/>
                </ListItemIcon>
                <ListItemText className="font-500 text-white font-subheading" disableTypography >
                  Products
                </ListItemText>
              </Link>
            </ListItemButton>
            <ListItemButton onClick={handleClick} className="px-2">
              <Link to={{pathname: router.adminOrders}} style={{ textDecoration: 'none', display: 'flex' }}>
                <ListItemIcon sx={{minWidth: '40px'}}>
                  <ShoppingCartOutlined sx={{color: 'white'}}/>
                </ListItemIcon> 
                <ListItemText className="font-500 text-white font-subheading" disableTypography >
                  Orders
                </ListItemText>
              </Link>
            </ListItemButton> */}
          </Paper>
        </List>
      </Paper>
    </Box>
  );
}

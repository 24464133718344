import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import router from "../../../routePaths/routePaths";
import BasicBreadcrumbs from "../BasicBreadcrumbs/BasicBreadcrumbs";
import MobileNavbar from "../Sidenav/MobileNavbar";
import HeaderView from "./HeaderView";

export default function Header(prop) {
    const navigate = useNavigate();
    const [openMobileNav, setOpenMobileNav] = useState(false);

    const logout = () =>{
        sessionStorage.removeItem("userLogged");
        navigate(router.login);
    }

    return (
        <div>
            <MobileNavbar
                openMobileNav = {openMobileNav}
                setOpenMobileNav = {()=>setOpenMobileNav(!openMobileNav)}
            />
            <HeaderView
                setOpenMobileNav = {()=>setOpenMobileNav(true)}
                logout = {()=>logout()}
                pageName = {prop.pageName}
            />
            <div className="row">
                <div className="col-md-2"></div>
                <div className="col-md-10 mt-2">
                    <BasicBreadcrumbs/>
                </div>
            </div>
        </div>
    );
}

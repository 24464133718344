import React, {useState, useEffect} from "react";
import {Button, Paper, Box, Autocomplete, TextField} from '@mui/material';
import Header from "../../Common/Header/Header";
import Sidenav from "../../Common/Sidenav/Sidenav";
import commonService from "../../../services/common-service";
import Spinner from "../../../shared/Spinner";

export default function NewPermitForm(props) {

    return (
        <Paper elevation={0} sx={{ width: '100%', p: 3}}>
        <form onSubmit={props.handleSubmit}>
        <div className="row">
            <div className='col-lg-6'>
                <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={props.installations}
                    getOptionLabel={(option) => option.ins_description}
                    name="selectedIns"
                    value={props.selectedIns}
                    onChange={(event, newValue)=>props.onInsSelect(newValue)}
                    renderInput={(params) =>
                    <TextField 
                        {...params} id="standard-basic" 
                        label="City" variant="standard" 
                        className="width-90" 
                        required
                    />}
            />
            </div>
            <div className='col-lg-6'>
                <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={props.groups}
                    getOptionLabel={(option) => option.GRP_DESCRIPTION}
                    name="selectedIns"
                    value={props.selectedGrp}
                    onChange={(event, newValue)=>props.onGrpSelect(newValue)}
                    renderInput={(params) =>
                    <TextField 
                        {...params} id="standard-basic" 
                        label="Zone" variant="standard" 
                        className="width-90" 
                        required
                    />}
            />
            </div>
            <div className='col-md-6'>
                <TextField
                    id="standard-basic" 
                    label="Permit Name" variant="standard" 
                    name="permit_name" value={props.inputField['permit_name']}
                    className="width-90" onChange={props.handleChange}
                    required/>
            </div>
            <div className='col-md-6'>
                <TextField
                    id="standard-basic" type="number"
                    label="Full Month Rate(in cents)" variant="standard" 
                    name="full_month_rate" value={props.inputField['full_month_rate']}
                    className="width-90" onChange={props.handleChange}
                    required/>
            </div>
            <div className='col-md-6'>
                <TextField
                    id="standard-basic" type="number"
                    label="Half Month Rate(in cents)" variant="standard" 
                    name="half_month_rate" value={props.inputField['half_month_rate']}
                    className="width-90" onChange={props.handleChange}
                    required/>
            </div>
        </div>
        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
            <Box sx={{ flex: '1 1 auto' }} />
            <Button type="submit" className="btn btn-blue px-3 fit-content">
                Submit
            </Button>
        </Box>
    </form>
        </Paper>
    );
}

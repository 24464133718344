import React, {useState, useEffect} from "react";
import {Button, Paper, Box, Autocomplete, TextField, Typography} from '@mui/material';
import Header from "../Common/Header/Header";
import Sidenav from "../Common/Sidenav/Sidenav";
import commonService from "../../services/common-service";
import Spinner from "../../shared/Spinner";
import SnackAlert from "../../shared/SnackAlert";
import { useParams } from "react-router-dom";

export default function ButtonToWheel() {
    const {domain} = useParams();
    const [installations, setInstallations] = useState([]);
    const [alert, setAlert] = React.useState(false);
    const [msg, setMsg] = React.useState('');
    const [severity, setSeverity] = React.useState('');
    const [groups, setGroups] = useState([]);
    const [selectedIns, setSelectedIns] = useState(null);
    const [selectedGrp, setSelectedGrp] = useState(null);
    const [spinner, setSpinner] = React.useState(false);
    
    const getInstalltions=async() => {
        setSpinner(true);
        const user = JSON.parse(sessionStorage.getItem('userLogged'));
        let body = {domain: domain};
        if(user.email == "cwpadmin"){
            body.ins_description = 'cwp'
        }
        const res = await commonService.get_button_ins(body);
        setInstallations(res.data);
        console.log(res.data)
        setSpinner(false);
    }

    const onInsSelect=async(newValue) => {
        if(newValue == null){
            return;
        }
        setSpinner(true);
        setSelectedIns(newValue);
        setSelectedGrp(null);
        const res = await commonService.get_button_groups({ins_id: newValue.ins_id, domain: domain});
        setGroups(res.data);
        if(res.data.length == 0){
            setAlert(true);
            setMsg("This city doesn't have any zone with button steps")
            setSeverity('error');
        }
        setSpinner(false);
    }

    const handleSubmit=async(e) => {
        e.preventDefault();
        setSpinner(true);
        const res = await commonService.button_to_wheel({tarbc_grp_id: selectedGrp.grp_id, domain: domain});
        if(res.data == true){
            setAlert(true);
            setMsg("Rate steps converted to time wheel successfully")
            setSeverity('success');
        }else{
            setAlert(true);
            setMsg("Something went wrong. Check your query again")
            setSeverity('error');
        }
        console.log(res.data);
        setSpinner(false);
    }

    useEffect(()=>{
        getInstalltions();
    },[])

    return (
        <div>
            <Header />
            <div className="main-container my-3">
                <div className="row m-2">
                    <div className="col-lg-2 col-xl-2 d-none d-lg-block d-xl-block">
                        <Sidenav/>
                    </div>
                    <div className="col-lg-10 col-xl-10">
                        <Paper elevation={2} sx={{ width: '100%', p: 3}}>
                            <Typography variant="body1" className="font-bold">Convert Button to Wheel</Typography>
                            <form onSubmit={handleSubmit}>
                                <div className="row">
                                    <div className='col-lg-6'>
                                        <Autocomplete
                                            disablePortal
                                            id="combo-box-demo"
                                            options={installations}
                                            getOptionLabel={(option) => option.ins_description}
                                            name="selectedIns"
                                            value={selectedIns}
                                            onChange={(event, newValue)=>onInsSelect(newValue)}
                                            renderInput={(params) =>
                                            <TextField 
                                                {...params} id="standard-basic" 
                                                label="City" variant="standard" 
                                                className="width-90" 
                                                required
                                            />}
                                    />
                                    </div>
                                    <div className='col-lg-6'>
                                        <Autocomplete
                                            disablePortal
                                            id="combo-box-demo"
                                            options={groups}
                                            getOptionLabel={(option) => option.grp_description}
                                            name="selectedIns"
                                            value={selectedGrp}
                                            onChange={(event, newValue)=>setSelectedGrp(newValue)}
                                            renderInput={(params) =>
                                            <TextField 
                                                {...params} id="standard-basic" 
                                                label="Zone" variant="standard" 
                                                className="width-90" 
                                                required
                                            />}
                                    />
                                    </div>
                                </div>
                                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                                    <Box sx={{ flex: '1 1 auto' }} />
                                    <Button type="submit" className="btn btn-blue px-3 fit-content">
                                        Convert to Wheel
                                    </Button>
                                </Box>
                            </form>
                        </Paper>
                    </div>
                </div>
            </div>
            <Spinner
                spinner = {spinner}
            />
            <SnackAlert 
                msg={msg}
                alert={alert}
                severity={severity}

                closeAlert={()=>setAlert(false)}
            />
        </div>
    );
}

import React, {useState, useEffect} from 'react'
import { Routes, Route, Navigate  } from 'react-router-dom'
import './App.css'
import AddDoorCode from './pages/AddDoorCode/AddDoorCode'
import ButtonToWheel from './pages/ButtonToWheel/ButtonToWheel'
import DisableZone from './pages/DisableZone/DisableZone'
import EnableZone from './pages/EnableZone/EnableZone'
import Login from './pages/Login/Login'
import ExternalPermit from './pages/MonthlyPermit/ExternalPermit/ExternalPermit'
import MonthlyPermit from './pages/MonthlyPermit/MonthlyPermit'
import NewMonthlyPermit from './pages/MonthlyPermit/NewMonthlyPermit/NewMonthlyPermit'
import UpdateMonthlyPermit from './pages/MonthlyPermit/UpdateMonthlyPermit/UpdateMonthlyPermit'
import Utility from './pages/RateConfigure/Utility'
import SelectDomain from './pages/SelectDomain/SelectDomain'
import SelectProfile from './pages/SelectProfile/SelectProfile'
import SelectRate from './pages/SelectRate/SelectRate'
import UpdateRate from './pages/UpdateRate/UpdateRate'
import router from './routePaths/routePaths'
// import firebase from './services/firebase';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { config } from './Constants'

const App = () => {
  const PrivateRoute = ({ children}) => {
    const isAdmin = JSON.parse(sessionStorage.getItem('userLogged'));
    if(isAdmin !== null){
      return children
    }
      
    return <Navigate to={router.login} />
  }

  function MissingRoute() {
    return <Navigate to={{pathname: router.select_profile}} />
  }

  const [user, setUser] = useState(null);

  useEffect(() => {
    // firebase.auth().onAuthStateChanged(user => {
    //   setUser(user);
    // })
  }, [])
  console.log(user);
  return (
    <Routes>
      <Route exact path={router.login} element={
        <GoogleOAuthProvider clientId={config.url.clientId}><Login/></GoogleOAuthProvider>
      }/>
      <Route exact path={router.new_rate+"/:id"+"/:domain"} element={
        <PrivateRoute>
          <Utility/>
        </PrivateRoute>}
      />
      <Route exact path={router.select_domain} element={
        <PrivateRoute>
          <SelectDomain/>
        </PrivateRoute>}
      />
      <Route exact path={router.select_profile+"/:domain"} element={
        <PrivateRoute>
          <SelectProfile/>
        </PrivateRoute>}
      />
      <Route exact path={router.select_rate+"/:domain"} element={
        <PrivateRoute>
          <SelectRate/>
        </PrivateRoute>}
      />
      <Route exact path={router.button_to_wheel+"/:domain"} element={
        <PrivateRoute>
          <ButtonToWheel/>
        </PrivateRoute>}
      />
      <Route exact path={router.monthly_permit+"/:domain"} element={
        <PrivateRoute>
          <MonthlyPermit/>
        </PrivateRoute>}
      />
      <Route exact path={router.new_monthly_permit+"/:domain"} element={
        <PrivateRoute>
          <NewMonthlyPermit/>
        </PrivateRoute>}
      />
      <Route exact path={router.update_monthly_permit+"/:domain"} element={
        <PrivateRoute>
          <UpdateMonthlyPermit/>
        </PrivateRoute>}
      />
      <Route exact path={router.external_permit+"/:domain"} element={
        <PrivateRoute>
          <ExternalPermit/>
        </PrivateRoute>}
      />
      <Route exact path={router.disable_zone+"/:domain"} element={
        <PrivateRoute>
          <DisableZone/>
        </PrivateRoute>}
      />
      <Route exact path={router.update_rate+"/:domain"} element={
        <PrivateRoute>
          <UpdateRate/>
        </PrivateRoute>}
      />
      <Route exact path={router.enable_zone+"/:domain"} element={
        <PrivateRoute>
          <EnableZone/>
        </PrivateRoute>}
      />
      <Route exact path={router.add_door_code+"/:domain"} element={
        <PrivateRoute>
          <AddDoorCode/>
        </PrivateRoute>}
      />
      {/* <Route exact path={router.button_to_wheel} element={<ButtonToWheel/>}/>
      <Route exact path={router.monthly_permit} element={<MonthlyPermit/>}/>
      <Route exact path={router.new_monthly_permit} element={<NewMonthlyPermit/>}/>
      <Route exact path={router.update_monthly_permit} element={<UpdateMonthlyPermit/>}/>
      <Route exact path={router.external_permit} element={<ExternalPermit/>}/>
      <Route exact path={router.disable_zone} element={<DisableZone/>}/>
      <Route exact path={router.update_rate} element={<UpdateRate/>}/>
      <Route exact path={router.enable_zone} element={<EnableZone/>}/> */}
      
      <Route path="*" element={<MissingRoute/>} />
    </Routes>
  );
}

export default App;
import React, {useState, useEffect} from "react";
import { useNavigate } from 'react-router-dom';
import {
    Box, TextField, Typography, Button, 
    Divider, InputAdornment, IconButton, Grid
} from '@mui/material';
import { Google, Visibility, VisibilityOff } from "@mui/icons-material";
import router from "../../routePaths/routePaths";
import Spinner from "../../shared/Spinner";
import SnackAlert from "../../shared/SnackAlert";
import { useGoogleLogin  } from '@react-oauth/google';
// import { signInWithGoogle } from '../../services/firebase';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    borderRadius: '5px',
    boxShadow: 24,
    p: 4,
  };
  
export default function Login() {
    const navigate = useNavigate();
    const [showPassword, setShowPassword] = useState(false);
    const [spinner, setSpinner] = React.useState(false);
    const [msg, setMsg] = React.useState('');
    const [alert, setAlert] = React.useState(false);
    const [severity, setSeverity] = React.useState('');
    const [inputField, setInputField] = useState({email:'',password:''});
    const handleChange = (e) => {
        setInputField({...inputField, [e.target.name]: e.target.value});
    }

    useEffect(()=>{
    },[])

    const submitForm = async (e) => {
        e.preventDefault();
        setSpinner(true);
        if((inputField.email == 'admin' && inputField.password == '2qPt$78Z6z!i') || (inputField.email == 'cwpadmin' && inputField.password == 'cwp2qPt$78Z6z!i')){
            sessionStorage.setItem("userLogged", JSON.stringify(inputField));
            navigate(router.select_domain);
        }else{
            setMsg('Invalid username or password');
            setAlert(true);
            setSeverity('error');
        }
        // const res = await authServices.adminLogin(inputField);
        // console.log(res.data);
        // if(res.data.error){
        //     setMsg(res.data.message);
        //     setAlert(true);
        //     setSeverity('error');
        // }else{
        //     sessionStorage.setItem("userLogged", JSON.stringify(res.data[0]));
        //     navigate(router.dashboard);
        // }
        setSpinner(false);
    }

    const auth = (res) =>{
        if(res.hd == 'integraparking.com'){
            sessionStorage.setItem("userLogged", JSON.stringify(res));
            navigate(router.select_domain);
        }else{
            setMsg('Invalid email');
            setAlert(true);
            setSeverity('error');
        }
        console.log(res);
    }

    const login = useGoogleLogin({
        onSuccess: codeResponse => auth(codeResponse),
        flow: 'auth-code',
        hint: 'hassan@integraparking.com',
        hosted_domain: 'integraparking.com'
      });

    return (
        <div className="main-container bg-black">
            <Box sx={style} className='width-50'>
                <Typography className='font-bold'>Login</Typography>
                <Divider className='my-2'/>
                <form onSubmit={submitForm}>
                    <div className='row'>
                        <div className='col-12 text-center'>
                            <TextField
                                id="standard-basic" 
                                label="Email" variant="standard"  
                                name='email' value={inputField.email}
                                className="width-90 mt-3" onChange={handleChange}
                                required/>
                        </div>
                        <div className='col-12 text-center'>
                            <TextField
                                id="standard-basic" 
                                label="Password" variant="standard"  
                                type={(showPassword) ? 'text':'password'}
                                name='password' value={inputField.password}
                                className="width-90 mt-3" onChange={handleChange}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">
                                        <IconButton
                                        onClick={()=>setShowPassword(!showPassword)}
                                        edge="end"
                                        >
                                        {showPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                    }}
                                required/>
                        </div>
                    </div>
                    <div className='row justify-content-center'>
                        <Button type='submit' className='btn btn-blue fit-content mt-4 px-4'>
                            Login
                        </Button>
                    </div>
                    {/* <button className="button" onClick={signInWithGoogle}><i className="fab fa-google"></i>Sign in with google</button> */}
                </form>
                <Grid container spacing={3} sx={{placeContent: "center", py: 2}}>
                    <Grid item xs={12}>
                        <Divider>OR</Divider>
                    </Grid>
                    <Grid item xs={12} align="center">
                    <Button color="primary" variant="outlined" onClick={() => login()}><Google/>&nbsp; Sign in with Google</Button>
                    </Grid>
                </Grid>
            </Box>
            <Spinner
                spinner = {spinner}
            />
            <SnackAlert 
                msg={msg}
                alert={alert}
                severity={severity}

                closeAlert={()=>setAlert(false)}
            />
        </div>
    );
}

/* global google */
import React, { useState, useRef, useCallback, useEffect } from "react";
import { 
  Button, Box
} from "@mui/material";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Polygon,
  Marker
} from "react-google-maps";
import GooglePlacesAutocomplete, { geocodeByPlaceId } from 'react-google-places-autocomplete';
import { DrawingManager } from "react-google-maps/lib/components/drawing/DrawingManager";

function GROUP_GEOMETRY(props) {
  const {zoom,center} = props;
  const [disabled, setDisabled] = useState(true);
  const [path, setPath] = useState([
    { lat: 52.52549080781086, lng: 13.398118538856465 },
    { lat: 52.48578559055679, lng: 13.36653284549709 },
    { lat: 52.48871246221608, lng: 13.44618372440334 }
  ]);
  const polygonRef = useRef(null);
  const listenersRef = useRef([]);

  useEffect(()=>{
    if(props.geometry.length > 1){
      let polygonQuery = '';
      props.geometry.map((x,index)=>{
        let query = "INSERT [dbo].[GROUPS_GEOMETRY] ([GRGE_GRP_ID], [GRGE_POL_NUMBER], [GRGE_ORDER], [GRGE_LATITUDE], [GRGE_LONGITUDE], [GRGE_INI_APPLY_DATE], [GRGE_END_APPLY_DATE])\n" +
        "VALUES ( "+props.grp_id+", 1, "+(index+1)+", "+x.lat+","+x.lng+","+" CAST(N'3/31/2022 3:13:17 PM' AS DateTime), CAST(N'1/1/2030 12:00:00 AM' AS DateTime));";
        polygonQuery = polygonQuery.concat("\n\n", query)
      })
      props.setGeometryQuery(polygonQuery);
      setDisabled(false);
    }      
  },[])

  const onPolygonComplete = (polygon, destroy = false) => {
    props.removePolygon();
    let coords = getPaths(polygon);
    props.setGeometry(coords);
    props.setCenter(coords[0]);
    let polygonQuery = '';
    coords.map((x,index)=>{
      let query = "INSERT [dbo].[GROUPS_GEOMETRY] ([GRGE_GRP_ID], [GRGE_POL_NUMBER], [GRGE_ORDER], [GRGE_LATITUDE], [GRGE_LONGITUDE], [GRGE_INI_APPLY_DATE], [GRGE_END_APPLY_DATE])\n" +
      "VALUES ( "+props.grp_id+", 1, "+(index+1)+", "+x.lat+","+x.lng+","+" CAST(N'3/31/2022 3:13:17 PM' AS DateTime), CAST(N'1/1/2030 12:00:00 AM' AS DateTime));";
      polygonQuery = polygonQuery.concat("\n\n", query)
    })
    props.setGeometryQuery(polygonQuery);
    let polygonIntegraQuery = '';
    coords.map((x,index)=>{
      let integraQuery = "INSERT [dbo].[GROUPS_GEOMETRY] ([GRGE_GRP_ID], [GRGE_POL_NUMBER], [GRGE_ORDER], [GRGE_LATITUDE], [GRGE_LONGITUDE], [GRGE_INI_APPLY_DATE], [GRGE_END_APPLY_DATE])\n"+
      "VALUES ( "+props.integra_grp_id+", 1, "+(index+1)+", "+x.lat+", "+x.lng+", CAST(N'3/31/2022 3:13:17 PM' AS DateTime), CAST(N'1/1/2030 12:00:00 AM' AS DateTime) );";
      polygonIntegraQuery = polygonIntegraQuery.concat("\n\n", integraQuery)
    })
    props.setGeometryIntegraQuery(polygonIntegraQuery);
    let polygonTicketQuery = '';
    coords.map((x,index)=>{
      let ticketQuery = "INSERT INTO GROUPS_GEOMETRY (GRGE_ID, GRGE_GRP_ID, GRGE_POL_NUMBER, GRGE_ORDER, GRGE_LATITUDE, GRGE_LONGITUDE, GRGE_INI_APPLY_DATE, GRGE_END_APPLY_DATE)\n"+
      "VALUES ( "+(props.ticket_grge_id+index)+","+props.ticket_grp_id+", 1, "+(index+1)+", "+x.lat+", "+x.lng+", TO_DATE('12/16/2021', 'mm/dd/yyyy') ,TO_DATE('1/1/2030', 'mm/dd/yyyy') );";
      polygonTicketQuery = polygonTicketQuery.concat("\n\n", ticketQuery)
    })
    props.setGeometryTicketQuery(polygonTicketQuery);
    setDisabled(false);
    // setPolygon(createPolygon(polygon.getPath()));

    // // Destroys the polygon that has been drawn by the manager.
    if (destroy) {
      polygon.setMap(null);
    }
  };

  const getPaths = (polygon) => {

    var polygonBounds = polygon.getPath();
    var bounds = [];
    for (var i = 0; i < polygonBounds.length; i++) {
      var point = {
        lat: polygonBounds.getAt(i).lat(),
        lng: polygonBounds.getAt(i).lng()
      };
      bounds.push(point);
    }
    return bounds;
  }

  const onEdit = useCallback(() => {
    if (polygonRef.current) {
      const nextPath = polygonRef.current
        .getPath()
        .getArray()
        .map(latLng => {
          return { lat: latLng.lat(), lng: latLng.lng() };
        });
      setPath(nextPath);
    }
  }, [setPath]);

  const onLoad = useCallback(
    polygon => {
      polygonRef.current = polygon;
      const path = polygon.getPath();
      listenersRef.current.push(
        path.addListener("set_at", onEdit),
        path.addListener("insert_at", onEdit),
        path.addListener("remove_at", onEdit)
      );
    },
    [onEdit]
  );

  // Clean up refs
  const onUnmount = useCallback(() => {
    listenersRef.current.forEach(lis => lis.remove());
    polygonRef.current = null;
  }, []);

  // console.log("The path state is", path);
  

  return (
    <>
      <div style={{position: 'absolute', width: '80%', top: '305px'}}>
        <GooglePlacesAutocomplete
            apiKey="AIzaSyDcPvfjCIqeUpGe8_IaI_-5rIZX9qtCUnI"
            selectProps={{
                onChange: (val) => {
                    geocodeByPlaceId(val.value.place_id)
                    .then(results => props.setCenter({
                    lat: results[0].geometry.location.lat(), 
                    lng: results[0].geometry.location.lng()
                    }))
                    .catch(error => console.error(error));
                }
            }}
        />
      </div>
      <div className="row">      
        <GoogleMap
          defaultZoom={zoom}
          center={center}
        >
          <Marker
            position={center}
          />
          <Polygon
            path={props.geometry}
            key={1}
            // editable={true}
            options={{
              strokeColor: "#032d6f",
              strokeOpacity: 0.8,
              strokeWeight: 2,
              fillColor: "#032d6f",
              fillOpacity: 0.35
            }}
            // onMouseUp={onEdit}
            //     // Event used when dragging the whole Polygon
            // onDragEnd={onEdit}
            // onLoad={onLoad}
            // onUnmount={onUnmount}
          />

          <DrawingManager
            defaultDrawingMode={google.maps.drawing.OverlayType.POLYGON}
            defaultOptions={{
            //   drawingControl: true,
              drawingControlOptions: {
                position: google.maps.ControlPosition.TOP_CENTER,
                drawingModes: [google.maps.drawing.OverlayType.POLYGON]
              },
            //   polygonOptions: {editable:true}
            }}
            onPolygonComplete={(polygon) => onPolygonComplete(polygon, true)}
          />
        </GoogleMap>
      </div>
      {/* <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
          <Button
              color="inherit"
              onClick={()=>props.handleBack()}
              sx={{ mr: 1 }}
              >
              Back
          </Button>
          <Box sx={{ flex: '1 1 auto' }} />
          <Button 
            type="submit" 
            className="btn btn-blue px-3 fit-content" 
            disabled={disabled}
            onClick={()=>props.handleNext()}>
              Next
          </Button>
      </Box> */}
    </>
  );
}

export default withScriptjs(withGoogleMap(GROUP_GEOMETRY));
import React from "react";
import { 
    Button, Typography, Paper, TextareaAutosize, Box
} from "@mui/material";

export default function UpdateRateQuery(props) {
    
    return (
        <div className="row">
            <Paper elevation={0} sx={{whiteSpace: 'pre-wrap', mt: 3, p: 2}}>
                <div className="row">
                    <Typography variant="h5" className="font-bold">dbPIC</Typography>
                    <div className="col-md-12">
                        <Typography variant="body1" className="font-bold">Update Description</Typography>
                        <TextareaAutosize
                            aria-label="minimum height"
                            minRows={2}
                            name="update_rate_query"
                            style={{ width: "100%", marginTop: '13px !important', border: 0, background: '#f0f0f0', padding: '15px'}}
                            value={props.inputQuery['update_rate_query']}
                            onChange={(e)=>props.handleQueryChange(e)}
                            ref={props.textAreaRef}
                        />
                    </div>
                    <div className="col-md-12">
                        <Typography variant="body1" className="font-bold">Delete Steps</Typography>
                        <TextareaAutosize
                            aria-label="minimum height"
                            minRows={2}
                            name="del_steps_query"
                            style={{ width: "100%", marginTop: '13px !important', border: 0, background: '#f0f0f0', padding: '15px'}}
                            value={props.inputQuery['del_steps_query']}
                            onChange={(e)=>props.handleQueryChange(e)}
                            ref={props.textAreaRef}
                        />
                    </div>
                    <div className="col-md-12">
                        <Typography variant="body1" className="font-bold">Rate Steps</Typography>
                        <TextareaAutosize
                            aria-label="minimum height"
                            minRows={2}
                            name="rate_steps_query"
                            style={{ width: "100%", marginTop: '13px !important', border: 0, background: '#f0f0f0', padding: '15px'}}
                            value={props.inputQuery['rate_steps_query']}
                            onChange={(e)=>props.handleQueryChange(e)}
                            ref={props.textAreaRef}
                        />
                    </div>
                    <div className="col-md-12">
                        <Typography variant="body1" className="font-bold">Tariff Constarints Enteries</Typography>
                        <TextareaAutosize
                            aria-label="minimum height"
                            minRows={2}
                            name="tce_query"
                            style={{ width: "100%", marginTop: '13px !important', border: 0, background: '#f0f0f0', padding: '15px'}}
                            value={props.inputQuery['tce_query']}
                            onChange={(e)=>props.handleQueryChange(e)}
                            ref={props.textAreaRef}
                        />
                    </div>
                </div>
            </Paper>
            <Box sx={{ display: 'flex', flexDirection: 'row', py: 2 }}>
                <Button
                    color="inherit"
                    onClick={()=>props.handleBack()}
                    sx={{ mr: 1 }}
                    >
                    Back
                </Button>
                <Box sx={{ flex: '1 1 auto' }} />
                <Button type="button" className="me-2" onClick={()=>{window.location.reload()}}>
                    Reset
                </Button>
                <Button type="button" className="btn btn-blue px-3 fit-content" onClick={props.insertToDB}>
                    Insert
                </Button>
            </Box>
        </div>
    );
}

import AxiosServices from './axios-service';
import {endpoints} from './api-end-points';

export class CommonService {

  get_record(body){    
    const res = AxiosServices.post(endpoints.common.get_record, body);
    return res;
  }

  get_installtions(body){    
    const res = AxiosServices.post(endpoints.common.get_installtions,body);
    return res;
  }

  get_integra_installtions(body){
    const res = AxiosServices.post(endpoints.common.get_integra_installtions,body);
    return res;
  }

  get_integra_ins_id(body){
    const res = AxiosServices.post(endpoints.common.get_integra_ins_id, body);
    return res;
  }

  get_ticket_ins_id(body){
    const res = AxiosServices.post(endpoints.common.get_ticket_ins_id, body);
    return res;
  }

  get_integra_groups_by_ins_id(body){
    const res = AxiosServices.post(endpoints.common.get_integra_groups_by_ins_id, body);
    return res;
  }

  get_last_ids(body){    
    const res = AxiosServices.post(endpoints.common.get_last_ids,body);
    return res;
  }

  get_groups_by_ins_id(body){
    const res = AxiosServices.post(endpoints.common.get_groups_by_ins_id, body);
    return res;
  }

  insert_to_db(body){
    const res = AxiosServices.post(endpoints.common.insert_to_db, body);
    return res;
  }

  insert_to_integra(body){
    const res = AxiosServices.post(endpoints.common.insert_to_integra, body);
    return res;
  }

  insert_to_ticket(body){
    const res = AxiosServices.post(endpoints.common.insert_to_ticket, body);
    return res;
  }

  get_button_ins(body){
    const res = AxiosServices.post(endpoints.common.get_button_ins,body);
    return res;
  }

  get_button_groups(body){
    const res = AxiosServices.post(endpoints.common.get_button_groups, body);
    return res;
  }

  button_to_wheel(body){
    const res = AxiosServices.post(endpoints.common.button_to_wheel, body);
    return res;
  }

  get_integra_group_by_dbPIC_grp_id(body){
    const res = AxiosServices.post(endpoints.common.get_integra_group_by_dbPIC_grp_id, body);
    return res;
  }

  get_monthly_permits_groups(body){
    const res = AxiosServices.post(endpoints.common.get_monthly_permits_groups, body);
    return res;
  }

  get_monthly_permit(body){
    const res = AxiosServices.post(endpoints.common.get_monthly_permit, body);
    return res;
  }

  update_monthly_permit(body){
    const res = AxiosServices.post(endpoints.common.update_monthly_permit, body);
    return res;
  }

  get_monthly_permit_by_grp_id(body){
    const res = AxiosServices.post(endpoints.common.get_monthly_permit_by_grp_id, body);
    return res;
  }
  
  get_grpt_by_ins_id(body){
    const res = AxiosServices.post(endpoints.common.get_grpt_by_ins_id, body);
    return res;
  }

  disable_zone(body){
    const res = AxiosServices.post(endpoints.common.disable_zone, body);
    return res;
  }

  get_rates_by_grp_id(body){
    const res = AxiosServices.post(endpoints.common.get_rates_by_grp_id, body);
    return res;
  }

  get_disabled_groups_by_ins_id(body){
    const res = AxiosServices.post(endpoints.common.get_disabled_groups_by_ins_id, body);
    return res;
  }

  enable_zone(body){
    const res = AxiosServices.post(endpoints.common.enable_zone, body);
    return res;
  }

  add_door_code(body){
    const res = AxiosServices.post(endpoints.common.add_door_code, body);
    return res;
  }

  get_tariffs_by_grp_id(body){
    const res = AxiosServices.post(endpoints.common.get_tariffs_by_grp_id, body);
    return res;
  }

}
const commonService = new CommonService();
export default commonService;

import React, {useState, useEffect} from "react";
import { 
    Button, Typography, Paper, TextareaAutosize, Box
} from "@mui/material";
import { ContentCopy } from "@mui/icons-material";
import commonService from "../../services/common-service";
import SnackAlert from "../../shared/SnackAlert";
import Spinner from "../../shared/Spinner";
import { useParams } from "react-router-dom";

export default function QUERY(props) {
    const {domain} = useParams();
    const [spinner, setSpinner] = React.useState(false);
    const [alert, setAlert] = React.useState(false);
    const [msg, setMsg] = React.useState('');
    const [severity, setSeverity] = React.useState('');
    const [queryObj, setQueryObj] = useState({});

    useEffect(() => {
        let obj = {};
        obj['group'] = props.groupQuery
        obj['group_geometry'] = props.geometryQuery;
        setQueryObj({...obj, ...props.queryObj});
      }, []);

    const handleChange=(e) => {
        setQueryObj({...queryObj, [e.target.name]: e.target.value});
    }

    const TextFile = () => {
        const element = document.createElement("a");
        const file = new Blob([document.getElementById('query').value], {type: 'text/plain'});
        element.href = URL.createObjectURL(file);
        element.download = props.inputGroup.grp_description + ".sql";
        document.body.appendChild(element); // Required for this to work in FireFox
        element.click();
    }
    
    const insertToDB = async () =>{
        setSpinner(true);
        Object.keys(queryObj).map(function(key, index) {
            queryObj[key] = queryObj[key].replace(/\n/g, '');
        });
        queryObj['domain'] = domain;
        const res = await commonService.insert_to_db(queryObj);
        if(res.data == true){
            setMsg("Zone Added Successfully in dbPIC");
            setSeverity('success');
            props.handleNext();
        }else{
            setMsg(res.data.map(x=>{return x}).join('\n\n'))
            setSeverity('error');
        }
        setAlert(true);
        setSpinner(false);
    }
    return (
        <div className="row">
            <Paper elevation={0} sx={{whiteSpace: 'pre-wrap', mt: 3, p: 2}}>
                {/* <div className="row">
                    <div className="col-6">
                        <Typography variant="body1" className="font-bold">QUERY</Typography>
                    </div>
                    <div className="col-6 text-end">
                        <Button onClick={props.copyToClipboard}><ContentCopy/></Button>
                    </div>
                </div> */}
                <div className="row">
                    <Typography variant="h5" className="font-bold">dbPIC</Typography>
                    <div className="col-md-12">
                        <Typography variant="body1" className="font-bold">Group</Typography>
                        <TextareaAutosize
                            aria-label="minimum height"
                            minRows={2}
                            name="group"
                            style={{ width: "100%", marginTop: '13px !important', border: 0, background: '#f0f0f0', padding: '15px'}}
                            value={queryObj['group']}
                            onChange={(e)=>handleChange(e)}
                            ref={props.textAreaRef}
                        />
                    </div>
                    <div className="col-md-12">
                        <Typography variant="body1" className="font-bold">Group Geometry</Typography>
                        <TextareaAutosize
                            aria-label="minimum height"
                            minRows={2}
                            name="group_geometry"
                            style={{ width: "100%", marginTop: '13px !important', border: 0, background: '#f0f0f0', padding: '15px'}}
                            value={queryObj['group_geometry']}
                            onChange={(e)=>handleChange(e)}
                            ref={props.textAreaRef}
                        />
                    </div>
                    <div className="col-md-12">
                        <Typography variant="body1" className="font-bold">Tariff</Typography>
                        <TextareaAutosize
                            aria-label="minimum height"
                            minRows={2}
                            name="tarif"
                            style={{ width: "100%", marginTop: '13px !important', border: 0, background: '#f0f0f0', padding: '15px'}}
                            value={queryObj['tarif']}
                            onChange={(e)=>handleChange(e)}
                            ref={props.textAreaRef}
                        />
                    </div>
                    <div className="col-md-12">
                        <Typography variant="body1" className="font-bold">Rate Type</Typography>
                        <TextareaAutosize
                            aria-label="minimum height"
                            minRows={2}
                            name="rate_type"
                            style={{ width: "100%", marginTop: '13px !important', border: 0, background: '#f0f0f0', padding: '15px'}}
                            value={queryObj['rate_type']}
                            onChange={(e)=>handleChange(e)}
                            ref={props.textAreaRef}
                        />
                    </div>
                    <div className="col-md-12">
                        <Typography variant="body1" className="font-bold">Rate Steps</Typography>
                        <TextareaAutosize
                            aria-label="minimum height"
                            minRows={2}
                            name="rate_steps"
                            style={{ width: "100%", marginTop: '13px !important', border: 0, background: '#f0f0f0', padding: '15px'}}
                            value={queryObj['rate_steps']}
                            onChange={(e)=>handleChange(e)}
                            ref={props.textAreaRef}
                        />
                    </div>
                    <div className="col-md-12">
                        <Typography variant="body1" className="font-bold">Day Types</Typography>
                        <TextareaAutosize
                            aria-label="minimum height"
                            minRows={2}
                            name="day_types"
                            style={{ width: "100%", marginTop: '13px !important', border: 0, background: '#f0f0f0', padding: '15px'}}
                            value={queryObj['day_types']}
                            onChange={(e)=>handleChange(e)}
                            ref={props.textAreaRef}
                        />
                    </div>
                    <div className="col-md-12">
                        <Typography variant="body1" className="font-bold">Day Hour Interval</Typography>
                        <TextareaAutosize
                            aria-label="minimum height"
                            minRows={2}
                            name="day_hour_interval"
                            style={{ width: "100%", marginTop: '13px !important', border: 0, background: '#f0f0f0', padding: '15px'}}
                            value={queryObj['day_hour_interval']}
                            onChange={(e)=>handleChange(e)}
                            ref={props.textAreaRef}
                        />
                    </div>
                    <div className="col-md-12">
                        <Typography variant="body1" className="font-bold">Rate Behaviour Set</Typography>
                        <TextareaAutosize
                            aria-label="minimum height"
                            minRows={2}
                            name="rate_behaviour_set"
                            style={{ width: "100%", marginTop: '13px !important', border: 0, background: '#f0f0f0', padding: '15px'}}
                            value={queryObj['rate_behaviour_set']}
                            onChange={(e)=>handleChange(e)}
                            ref={props.textAreaRef}
                        />
                    </div>
                    <div className="col-md-12">
                        <Typography variant="body1" className="font-bold">Rate Behaviour Step</Typography>
                        <TextareaAutosize
                            aria-label="minimum height"
                            minRows={2}
                            name="rate_behaviour_step"
                            style={{ width: "100%", marginTop: '13px !important', border: 0, background: '#f0f0f0', padding: '15px'}}
                            value={queryObj['rate_behaviour_step']}
                            onChange={(e)=>handleChange(e)}
                            ref={props.textAreaRef}
                        />
                    </div>
                    <div className="col-md-12">
                        <Typography variant="body1" className="font-bold">Tariff Constraint Set</Typography>
                        <TextareaAutosize
                            aria-label="minimum height"
                            minRows={2}
                            name="tariff_constraint_set"
                            style={{ width: "100%", marginTop: '13px !important', border: 0, background: '#f0f0f0', padding: '15px'}}
                            value={queryObj['tariff_constraint_set']}
                            onChange={(e)=>handleChange(e)}
                            ref={props.textAreaRef}
                        />
                    </div>
                    <div className="col-md-12">
                        <Typography variant="body1" className="font-bold">Tariff Constraint Enteries</Typography>
                        <TextareaAutosize
                            aria-label="minimum height"
                            minRows={2}
                            name="tariff_constraint_enteries"
                            style={{ width: "100%", marginTop: '13px !important', border: 0, background: '#f0f0f0', padding: '15px'}}
                            value={queryObj['tariff_constraint_enteries']}
                            onChange={(e)=>handleChange(e)}
                            ref={props.textAreaRef}
                        />
                    </div>
                    <div className="col-md-12">
                        <Typography variant="body1" className="font-bold">Tariff Definition Rules</Typography>
                        <TextareaAutosize
                            aria-label="minimum height"
                            minRows={2}
                            name="tariff_definition_rules"
                            style={{ width: "100%", marginTop: '13px !important', border: 0, background: '#f0f0f0', padding: '15px'}}
                            value={queryObj['tariff_definition_rules']}
                            onChange={(e)=>handleChange(e)}
                            ref={props.textAreaRef}
                        />
                    </div>
                    <div className="col-md-12">
                        <Typography variant="body1" className="font-bold">Tariff Application Rules</Typography>
                        <TextareaAutosize
                            aria-label="minimum height"
                            minRows={2}
                            name="tariff_application_rules"
                            style={{ width: "100%", marginTop: '13px !important', border: 0, background: '#f0f0f0', padding: '15px'}}
                            value={queryObj['tariff_application_rules']}
                            onChange={(e)=>handleChange(e)}
                            ref={props.textAreaRef}
                        />
                    </div>
                </div>
            </Paper>
            <Box sx={{ display: 'flex', flexDirection: 'row', py: 2 }}>
                <Button
                    color="inherit"
                    onClick={()=>props.handleBack()}
                    sx={{ mr: 1 }}
                    >
                    Back
                </Button>
                <Box sx={{ flex: '1 1 auto' }} />
                <Button type="button" className="me-2" onClick={()=>{props.handleNext();}}>
                    Next
                </Button>
                {/* <Button type="button" className="btn btn-blue px-3 me-2 fit-content" onClick={()=>{TextFile()}}>
                    Download Script
                </Button> */}
                <Button type="button" className="btn btn-blue px-3 fit-content" onClick={()=>{insertToDB()}}>
                    Insert to dbPIC
                </Button>
            </Box>
            <Spinner
                spinner = {spinner}
            />
            <SnackAlert 
                msg={msg}
                alert={alert}
                severity={severity}

                closeAlert={()=>setAlert(false)}
            />
        </div>
    );
}

import { config } from '../Constants';
export const endpoints = {

  ///---Common service--///

  common : {
    get_installtions : config.url.API_URL + 'get_installtions',
    get_integra_installtions : config.url.API_URL + 'get_integra_installtions',
    get_last_ids : config.url.API_URL + 'get_last_ids',
    get_groups_by_ins_id : config.url.API_URL + 'get_groups_by_ins_id',
    get_integra_groups_by_ins_id : config.url.API_URL + 'get_integra_groups_by_ins_id',
    insert_to_db : config.url.API_URL + 'insert_to_db',
    get_integra_ins_id : config.url.API_URL + 'get_integra_ins_id',
    get_ticket_ins_id : config.url.API_URL + 'get_ticket_ins_id',
    insert_to_integra : config.url.API_URL + 'insert_to_integra',
    insert_to_ticket : config.url.API_URL + 'insert_to_ticket',
    get_button_ins : config.url.API_URL + 'get_button_ins',
    get_button_groups : config.url.API_URL + 'get_button_groups',
    button_to_wheel : config.url.API_URL + 'button_to_wheel',
    get_integra_group_by_dbPIC_grp_id : config.url.API_URL + 'get_integra_group_by_dbPIC_grp_id',
    get_monthly_permits_groups : config.url.API_URL + 'get_monthly_permits_groups',
    get_monthly_permit : config.url.API_URL + 'get_monthly_permit',
    update_monthly_permit : config.url.API_URL + 'update_monthly_permit',
    get_monthly_permit_by_grp_id : config.url.API_URL + 'get_monthly_permit_by_grp_id',
    get_grpt_by_ins_id : config.url.API_URL + 'get_grpt_by_ins_id',
    disable_zone : config.url.API_URL + 'disable_zone',
    get_rates_by_grp_id : config.url.API_URL + 'get_rates_by_grp_id',
    get_disabled_groups_by_ins_id : config.url.API_URL + 'get_disabled_groups_by_ins_id',
    enable_zone : config.url.API_URL + 'enable_zone',
    add_door_code : config.url.API_URL + 'add_door_code',
    get_tariffs_by_grp_id : config.url.API_URL + 'get_tariffs_by_grp_id',
  },

}
import * as React from 'react';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function SnackAlert(prop) {

  return (
    <Stack spacing={2} sx={{ width: '100%' }}>
      <Snackbar 
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }} 
        open={prop.alert} 
        // autoHideDuration={12000} 
        onClose={prop.closeAlert}
        sx={{whiteSpace: 'pre-wrap', maxHeight:"100%", overflow: 'auto', top:'0 !important'}}
      >
        <Alert onClose={prop.closeAlert} severity={prop.severity} sx={{ width: '100%', marginTop: '24px' }}>
          {prop.msg}
        </Alert>
      </Snackbar>
    </Stack>
  );
}
import React, { useEffect } from "react";
import {Button, Paper, Box, Autocomplete, TextField, Typography} from '@mui/material';
import Geometry from "./Geometry";
import { useNavigate, useParams } from "react-router-dom";
import router from "../../routePaths/routePaths";

export default function UpdateRateForm(props) {
    let navigate = useNavigate();
    const {domain} = useParams()

    useEffect(()=>{},[props.rates])

    return (
        <Paper elevation={0} sx={{ width: '100%', p: 3}}>
            <Typography variant="body1" className="font-bold">Update Rate</Typography>
            <form onSubmit={props.handleSubmit}>
                <div className="row">
                    <div className='col-lg-6'>
                        <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            options={props.installations}
                            getOptionLabel={(option) => option.ins_description}
                            name="selectedIns"
                            value={props.selectedIns}
                            onChange={(event, newValue)=>props.onInsSelect(newValue)}
                            renderInput={(params) =>
                            <TextField 
                                {...params} id="standard-basic" 
                                label="City" variant="standard" 
                                className="width-90 mt-2" 
                                required
                            />}
                    />
                    </div>
                    <div className='col-lg-6'>
                        <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            options={props.groups}
                            getOptionLabel={(option) => option.GRP_DESCRIPTION}
                            name="selectedIns"
                            value={props.selectedGrp}
                            onChange={(event, newValue)=>props.onGrpSelect(newValue)}
                            renderInput={(params) =>
                            <TextField 
                                {...params} id="standard-basic" 
                                label="Zone" variant="standard" 
                                className="width-90 mt-2" 
                                required
                            />}
                    />
                    </div>
                    <div className='col-lg-12 mt-2'>
                        {props.polygon.length > 0 && <Geometry
                            googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyDcPvfjCIqeUpGe8_IaI_-5rIZX9qtCUnI&libraries=drawing,places"
                            loadingElement={<div style={{ height: `100%` }} />}
                            containerElement={<div style={{ height: `55vh` }} />}
                            mapElement={<div style={{ height: `100%` }} />}
                            polygon={props.polygon}
                        />}
                    </div>
                </div>
                {props.rates.length > 0 && 
                    props.rates.map((x,index) => {
                        return(
                            <div className="row">
                                <div className='col-lg-6'>
                                    <TextField
                                        id="standard-basic"
                                        label={x.RAT_DESCRIPTION} variant="outlined" 
                                        name={x.RAS_RAT_ID+"_"+index+"_desc"} value={props.inputField[x.RAS_RAT_ID+"_"+index+"_desc"]}
                                        className="width-90 mt-4" onChange={props.handleChange}
                                        InputLabelProps={{ shrink: true }} required size='small'/>
                                </div>
                                <div className='col-lg-6'>
                                    <div className='show-time'>  
                                    <b>Time: </b>
                                    {x.dah_desc.map((element,key) => {
                                            return(
                                                <span>
                                                    {element}
                                                    {(key !== (x.dah_desc.length-1)) ? ' |': ''}
                                                </span>
                                            )
                                        })
                                    }
                                    </div>
                                </div>
                                <div className="row">
                                {/* <Typography variant="subtitle" className="font-bold mt-3">{x.RAT_DESCRIPTION}</Typography> */}
                                        {x.RATE_STEPS.map((y,z)=>{
                                            return(
                                                <>
                                                    {z == 0 && <div className='col-lg-6'>
                                                        <TextField
                                                            id="standard-basic" type={'number'}
                                                            label={(x.RATE_STEPS.length > 1)?"Minimum in cents":"Rate in Cents"} variant="standard" 
                                                            name={x.RAS_RAT_ID+"_"+z+"_rate"} value={props.inputField[x.RAS_RAT_ID+"_"+z+"_rate"]}
                                                            className="width-90 mt-2" onChange={props.handleChange}
                                                            InputLabelProps={{ shrink: true }} required/>
                                                    </div>}
                                                    {z == x.RATE_STEPS.length-1 && x.RATE_STEPS.length > 1 && <div className='col-lg-6'>
                                                        <TextField
                                                            id="standard-basic" type={'number'}
                                                            label={"Maximum in cents"} variant="standard" 
                                                            name={x.RAS_RAT_ID+"_"+z+"_rate"} value={props.inputField[x.RAS_RAT_ID+"_"+z+"_rate"]}
                                                            className="width-90 mt-2" onChange={props.handleChange}
                                                            InputLabelProps={{ shrink: true }} required/>
                                                    </div>}
                                                </>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        )
                    })
                }
                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                    <Box sx={{ flex: '1 1 auto' }} />
                    <Button className="btn btn-blue px-3 mx-2 fit-content" onClick={()=>navigate(router.new_rate+'/24_hr_rate'+'/'+domain)}>
                        Add 24hr rate
                    </Button>
                    <Button className="btn btn-blue px-3 mx-2 fit-content" onClick={()=>navigate(router.new_rate+'/day_night_weekend_rate'+'/'+domain)}>
                        Add Day, Night and Weekend rate
                    </Button>
                    <Button className="btn btn-blue px-3 mx-2 fit-content" onClick={()=>navigate(router.new_rate+'/overlapped_rate'+'/'+domain)}>
                        Add Overlapped rate
                    </Button>
                    <Button type="submit" className="btn btn-blue px-3 fit-content" disabled={props.rates.length == 0}>
                        Submit
                    </Button>
                </Box>
            </form>
        </Paper>
    );
}

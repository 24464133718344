import React, {useState, useEffect} from "react";
import { 
    Button, Typography, Paper, Box, TextField, Autocomplete 
} from "@mui/material";
export default function GROUPS(props) {
    const [inputGroup, setInputGroup] = useState({});
    const [group, setGroup] = useState('');
    
    
    const handleChange=(e) => {
        setInputGroup({...inputGroup, [e.target.name]: e.target.value});
    }

    const handleSubmit=(e) => {
        e.preventDefault();
        let group = "INSERT INTO [dbo].[GROUPS] ([GRP_ID],[GRP_DESCRIPTION],[GRP_INS_ID],[GRP_QUERY_EXT_ID],[GRP_EXT1_ID],[GRP_EXT2_ID],[GRP_EXT3_ID],[GRP_HASH],[GRP_TREE_TYPE],[GRP_LOG_GRP_ID])\n" +
        "VALUES ("+props.inputGroup.grp_id+","+"'"+props.inputGroup.grp_description+"'"+","+props.inputGroup.ins_id+",null,null,null,null,null,0,null)";
        setGroup(group);
        props.setGroupQuery(group);
        let integraGroup = "INSERT INTO [dbo].[LITERALS]([LIT_ID],[LIT_DESCRIPTION],[LIT_KEY])\n"+
        "VALUES("+props.inputRate.integra_lit_id+",'"+props.inputGroup.grp_description+"',null)\n\n"+
        "INSERT INTO [dbo].[LITERALS]([LIT_ID],[LIT_DESCRIPTION],[LIT_KEY])\n"+
        "VALUES("+(props.inputRate.integra_lit_id+1)+",'"+props.inputGroup.grp_description+"',null)\n\n"+
        "INSERT INTO [dbo].[GROUPS]([GRP_ID],[GRP_DESCRIPTION],[GRP_INS_ID],[GRP_LIT_ID],[GRP_SHOW_ID],[GRP_COLOUR],[GRP_QUERY_EXT_ID],[GRP_EXT1_ID],[GRP_EXT2_ID],[GRP_EXT3_ID],[GRP_ID_FOR_EXT_OPS],[GRP_FREE_SPACES_ACQUISITION_UTC_DATE],[GRP_FREE_SPACES_NUM],[GRP_FREE_SPACES_PERC],[GRP_TYPE],[GRP_OFFSTREET_TYPE],[GRP_DESCRIPTION2],[GRP_PERMIT_MAX_MONTHS],[GRP_PERMIT_MAX_CUR_MONTH_DAY],[GRP_MESSAGE_LIT_ID])\n"+
        "VALUES("+props.inputRate.integra_grp_id+",'"+props.inputGroup.grp_description+"',"+props.integraInsId+","+props.inputRate.integra_lit_id+",123,null,"+props.inputGroup.grp_id+","+props.inputGroup.grp_id+","+props.inputGroup.grp_id+","+props.inputGroup.grp_id+","+props.inputGroup.grp_id+",null,null,null,0,null,null,4,null,null)" +
        "INSERT INTO [dbo].[GROUPS] ([GRP_ID],[GRP_DESCRIPTION],[GRP_INS_ID],[GRP_QUERY_EXT_ID],[GRP_EXT1_ID],[GRP_EXT2_ID],[GRP_EXT3_ID],[GRP_HASH],[GRP_TREE_TYPE],[GRP_LOG_GRP_ID])\n" +
        "VALUES ("+props.inputRate.integra_grp_id+","+"'"+props.inputGroup.grp_description+"'"+","+props.integraInsId+",null,null,null,null,null,0,null)\n\n"+
        "INSERT INTO [dbo].[GROUPS_HIERARCHY]([GRHI_GPR_ID_PARENT],[GRHI_GPR_ID_CHILD],[GRHI_INI_APPLY_DATE],[GRHI_END_APPLY_DATE])\n"+
        "VALUES(null,"+props.inputRate.integra_grp_id+",'2021-11-26 00:00:00.000','2030-01-01 00:00:00.000')";
        props.setIntegraGroupQuery(integraGroup);
        let ticketGroup = "INSERT INTO STREETS (STR_ID, STR_DESCRIPTION, STR_INS_ID, STR_LOOKUP_DESCRIPTION)\n"+
        "VALUES ("+props.inputRate.ticket_str_id+", '"+props.inputGroup.grp_description+"', "+props.ticketInsId+", '"+props.inputGroup.grp_description+"');\n\n"+
        "INSERT INTO GROUPS (GRP_ID, GRP_DESCRIPTION, GRP_INS_ID, GRP_QUERY_EXT_ID, GRP_EXT1_ID, GRP_EXT2_ID, GRP_EXT3_ID, GRP_STR_ID)\n"+
        "VALUES ("+props.inputRate.ticket_grp_id+", '"+props.inputGroup.grp_description+"', "+props.ticketInsId+", "+props.inputGroup.grp_id+", "+props.inputGroup.grp_id+", "+props.inputGroup.grp_id+", "+props.inputGroup.grp_id+", "+props.inputRate.ticket_str_id+");\n\n"+
        props.setTicketGroupQuery(ticketGroup);
        props.handleNext();
    }

    const polygon = (val) =>{
        let groupQuery = group.concat(val)
        props.query(groupQuery);
    }


    return (
        <div className="main-container my-3">
            <div className="row m-2">
                <Paper elevation={0} sx={{whiteSpace: 'pre-wrap', p: 2}}>
                    <Typography variant="h6" className="font-bold">Add Group</Typography>
                    <form onSubmit={handleSubmit}>
                        <div className="row">
                            <div className='col-lg-6'>
                                <Autocomplete
                                    disablePortal
                                    id="combo-box-demo"
                                    options={props.installations}
                                    getOptionLabel={(option) => option.ins_description}
                                    name="selectedIns"
                                    value={props.selectedIns}
                                    onChange={(event, newValue)=>props.onInsSelect(newValue)}
                                    renderInput={(params) =>
                                    <TextField 
                                        {...params} id="standard-basic" 
                                        label="City" variant="standard" 
                                        className="width-90" 
                                        required
                                    />}
                            />
                            </div>
                            <div className='col-md-6'>
                                <TextField
                                    id="standard-basic" 
                                    label="Group Name" variant="standard" 
                                    name="grp_description" value={props.inputGroup['grp_description']}
                                    className="width-90" onChange={props.handleGroupChange}
                                    required/>
                            </div>
                        </div>
                        {/* <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                            <Box sx={{ flex: '1 1 auto' }} />
                            <Button type="submit" className="btn btn-blue px-3 fit-content">
                                Next
                            </Button>
                        </Box> */}
                    </form>
                </Paper>
            </div>
        </div>
    );
}

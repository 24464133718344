export class PermitQueryGenerator {  
  
  newMonthlyPermit(inputField){
    let tarif = "INSERT INTO [dbo].[TARIFFS]([TAR_ID],[TAR_DESCRIPTION],[TAR_INS_ID])\n"+
    "VALUES("+inputField.TAR_ID+",'"+inputField.permit_name+"',"+inputField.ins_id+")";
    
    let monthly_permit_definition = "INSERT INTO [dbo].[MONTHLY_PERMITS_DEFINITIONS]([MPF_TAR_ID],[MPF_COURTESY_DAYS],[MPF_AMOUNT_MONTH],[MPF_AMOUNT_HALF_MONTH],[MPF_START_HALF_MONTH_TARIFF_DAY],[MPF_START_HALF_MONTH_DAY],[MPF_RENEWAL_DAY],[MPF_INI_APPLY_DATE],[MPF_END_APPLY_DATE],[MPF_MAX_PLATES],[MPF_VERIF_INI_HOUR],[MPF_MAX_MONTHS],[MPF_MAX_CUR_MONTH_DAY])\n"+
    "VALUES("+inputField.TAR_ID+",2,"+inputField.full_month_rate+","+inputField.half_month_rate+",10,15,25,'2021-03-01 00:00:00.000','2050-03-01 00:00:00.000',3,'00:00',4,NULL)";
    
    let tariff_constraint_set = "INSERT INTO [dbo].[TARIFF_CONSTRAINTS_SETS]([TCS_ID],[TCS_DESCRIPTION],[TCS_INS_ID])\n"+
    "VALUES("+inputField.TCS_ID+",'"+inputField.permit_name+"',"+inputField.ins_id+")";
    
    let tariff_application_rules = "INSERT INTO [dbo].[TARIFFS_APPLICATION_RULES]([TAPR_DESCRIPTON],[TAPR_TAR_ID],[TAPR_UNI_ID],[TAPR_GRP_ID],[TAPR_GRPT_ID],[TAPR_INI_APPLY_DATE],[TAPR_END_APPLY_DATE],[TAPR_DAT_ID],[TAPR_DAH_ID],[TARP_IN_DAY_INI_HOUR],[TARP_IN_DAY_END_HOUR],[TAPR_IN_YEAR_INI_DATE],[TAPR_IN_YEAR_END_DATE],[TAPR_SHOW_ORDER],[TAPR_ALLOW_USER_SELECT],[TAPR_INSERT_PLATE],[TAPR_SPACE_MANAGMENT_TYPE],[TAPR_TCS_ID],[TAPR_PLATE_TARIFF_ASSIGN_TYPE],[TAPR_TIMEAMOUNT_SEL_TYPE],[TAPR_PAY_MODE],[TAPR_INC1],[TAPR_INC2],[TAPR_INC3],[TAPR_INC4],[TAPR_INC5],[TAPR_DEC1],[TAPR_DEC2],[TAPR_DEC3],[TAPR_DEC4],[TAPR_DEC5],[TAPR_ACCESS_CARD_TYPE],[TAPR_ACCESS_CARD_NUM],[TAPR_CONNECTION_REQUIRED],[TAPR_ALLOW_REMOTE_EXTENSION],[TAPR_TARIFF_ENGINE],[TAPR_BNF_ID],[TAPR_ALLOW_VOUCHERS],[TAPR_VOUCHER_ASSIGN_TYPE],[TAPR_VOUCHER_ASSIGN_VALUE],[TAPR_PERMITTED_ASSIGNED_TARIFFS],[TAPR_AUTO_ASSIGNED_TARIFFS],[TAPR_COMPUTESTEPS_TIMEAMOUNT],[TAPR_COMPUTESTEPS_OFFSET],[TAPR_ALLOW_STEP_OVERPAY],[TAPR_ALLOWED_PAYMENT_METHODS])\n" +
    "VALUES('"+inputField.permit_name+"',"+inputField.TAR_ID+",null,"+inputField.grp_id+",null,'2019-03-01 00:00:00.000','2050-01-01 00:00:00.000',null,null,null,null,null,null,1,1,1,0,"+inputField.TCS_ID+",1,0,0,5,10,30,60,120,5,10,30,60,120,null,null,null,0,12,null,0,null,null,null,null,null,null,0,null)";
    
    let literals = "INSERT INTO [dbo].[LITERALS]([LIT_ID],[LIT_DESCRIPTION],[LIT_KEY])\n"+
    "VALUES("+inputField.integra_lit_id+",'"+inputField.permit_name+"',null)";
    
    let integra_tarif = "INSERT INTO [dbo].[TARIFFS]([TAR_ID],[TAR_DESCRIPTION],[TAR_LIT_ID],[TAR_QUERY_EXT_ID],[TAR_EXT1_ID],[TAR_EXT2_ID],[TAR_EXT3_ID],[TAR_ID_FOR_EXT_OPS],[TAR_INS_ID],[TAR_MIN_COIN],[TAR_TYPE],[TAR_PERMIT_RENEWAL_DAY],[TAR_MAX_PLATES],[TAR_MAX_PERMITS_ONCE],[TAR_BEHAVIOR],[TAR_APP_MIN_VERSION],[TAR_APP_MAX_VERSION],[TAR_SERTYP_ID],[TAR_AUTOSTART],[TAR_RESTART_TARIFF],[TAR_NOTICE_CHARGES_NOW],[TAR_NOTICE_CHARGES_NOW_LIT_ID],[TAR_SHOPKEEPER_BEHAVIOR_TYPE])\n"+
    "VALUES("+inputField.integra_tar_id+",'"+inputField.permit_name+"',"+(inputField.integra_lit_id)+","+inputField.TAR_ID+","+inputField.TAR_ID+","+inputField.TAR_ID+","+inputField.TAR_ID+","+inputField.TAR_ID+","+inputField.integra_ins_id+",null,1,25,3,10,0,null,null,null,null,null,0,null,0)";

    let integra_tarif_in_group = "INSERT INTO [dbo].[TARIFFS_IN_GROUPS]([TARGR_GRP_ID],[TARGR_GRPT_ID],[TARGR_TAR_ID],[TARGR_TIME_STEPS_VALUE],[TARGR_LIT_ID],[TARGR_USER_SELECTABLE],[TARGR_INI_APPLY_DATE],[TARGR_END_APPLY_DATE],[TARGR_STEP1_MIN],[TARGR_STEP1_LIT_ID],[TARGR_STEP1_ENABLED],[TARGR_STEP2_MIN],[TARGR_STEP2_LIT_ID],[TARGR_STEP2_ENABLED],[TARGR_STEP3_MIN],[TARGR_STEP3_LIT_ID],[TARGR_STEP3_ENABLED],[TARGR_STEP4_MIN],[TARGR_STEP4_LIT_ID],[TARGR_STEP4_ENABLED],[TARGR_STEP5_MIN],[TARGR_STEP5_LIT_ID],[TARGR_STEP5_ENABLED],[TARGR_STEP6_MIN],[TARGR_STEP6_LIT_ID],[TARGR_STEP6_ENABLED],[TARGR_STEP7_MIN],[TARGR_STEP7_LIT_ID],[TARGR_STEP7_ENABLED],[TARGR_STEP8_MIN],[TARGR_STEP8_LIT_ID],[TARGR_STEP8_ENABLED],[TARGR_STEP9_MIN],[TARGR_STEP9_LIT_ID],[TARGR_STEP9_ENABLED],[TARGR_STEP10_MIN],[TARGR_STEP10_LIT_ID],[TARGR_STEP10_ENABLED],[TARGR_LAYOUT],[TARGR_PARKING_MODE],[TARGR_TAR_INFO2_LIT_ID],[TARGR_TAR_INFO3_LIT_ID],[TARGR_TAR_INFO4_LIT_ID],[TARGR_TAR_INFO5_LIT_ID],[TARGR_TAR_INFO6_LIT_ID],[TARGR_TAR_INFO7_LIT_ID],[TARGR_TAR_INFO8_LIT_ID],[TARGR_TAR_INFO9_LIT_ID],[TARGR_TAR_INFO10_LIT_ID],[TARGR_INCLUDE_ZERO_STEP],[TARGR_TIME_DEF_STEP],[TARGR_POLYGON_SHOW],[TARGR_POLYGON_COLOUR],[TARGR_POLYGON_Z],[TARGR_POLYGON_MAP_DESCRIPTION])\n" +
    "VALUES("+inputField.integra_grp_id+",null,"+inputField.integra_tar_id+",100,"+(inputField.integra_lit_id)+",1,'2021-06-01 00:00:00.000','2050-01-01 00:00:00.000',null,null,0,null,null,0,null,null,0,null,null,0,null,null,0,null,null,0,null,null,0,null,null,0,null,null,0,null,null,0,1,1,null,null,null,null,null,null,null,null,null,1,0,null,null,null,null);"

    let queryObj = {
      tarif: tarif, 
      monthly_permit_definition: monthly_permit_definition, 
      tariff_constraint_set: tariff_constraint_set,
      tariff_application_rules: tariff_application_rules,
    }
    let integraObj = {
      literals: literals,
      integra_tarif: integra_tarif,
      integra_tarif_in_group: integra_tarif_in_group
    }
    
    return {
        queryObj: queryObj,
        integraObj: integraObj
      }
  }

  newExternalPermit(inputField){
    console.log(inputField)
    let grpt = "INSERT INTO [dbo].[GROUPS_TYPES]([GRPT_ID],[GRPT_INS_ID],[GRPT_DESCRIPTION])\n"+
    "VALUES("+inputField.grpt_id+","+inputField.ins_id+",'"+inputField.ext_permit_name+"')";

    let tarif = "INSERT INTO [dbo].[TARIFFS]([TAR_ID],[TAR_DESCRIPTION],[TAR_INS_ID])\n"+
    "VALUES("+inputField.TAR_ID+",'"+inputField.ext_permit_name+"',"+inputField.ins_id+")";
    
    let monthly_permit_definition = "INSERT INTO [dbo].[MONTHLY_PERMITS_DEFINITIONS]([MPF_TAR_ID],[MPF_COURTESY_DAYS],[MPF_AMOUNT_MONTH],[MPF_AMOUNT_HALF_MONTH],[MPF_START_HALF_MONTH_TARIFF_DAY],[MPF_START_HALF_MONTH_DAY],[MPF_RENEWAL_DAY],[MPF_INI_APPLY_DATE],[MPF_END_APPLY_DATE],[MPF_MAX_PLATES],[MPF_VERIF_INI_HOUR],[MPF_MAX_MONTHS],[MPF_MAX_CUR_MONTH_DAY])\n"+
    "VALUES("+inputField.TAR_ID+",2,0,0,10,15,25,'2021-03-01 00:00:00.000','2050-03-01 00:00:00.000',3,'00:00',4,NULL)";
    
    let tariff_constraint_set = "INSERT INTO [dbo].[TARIFF_CONSTRAINTS_SETS]([TCS_ID],[TCS_DESCRIPTION],[TCS_INS_ID])\n"+
    "VALUES("+inputField.TCS_ID+",'"+inputField.ext_permit_name+"',"+inputField.ins_id+")";
    
    let tariff_application_rules = "INSERT INTO [dbo].[TARIFFS_APPLICATION_RULES]([TAPR_DESCRIPTON],[TAPR_TAR_ID],[TAPR_UNI_ID],[TAPR_GRP_ID],[TAPR_GRPT_ID],[TAPR_INI_APPLY_DATE],[TAPR_END_APPLY_DATE],[TAPR_DAT_ID],[TAPR_DAH_ID],[TARP_IN_DAY_INI_HOUR],[TARP_IN_DAY_END_HOUR],[TAPR_IN_YEAR_INI_DATE],[TAPR_IN_YEAR_END_DATE],[TAPR_SHOW_ORDER],[TAPR_ALLOW_USER_SELECT],[TAPR_INSERT_PLATE],[TAPR_SPACE_MANAGMENT_TYPE],[TAPR_TCS_ID],[TAPR_PLATE_TARIFF_ASSIGN_TYPE],[TAPR_TIMEAMOUNT_SEL_TYPE],[TAPR_PAY_MODE],[TAPR_INC1],[TAPR_INC2],[TAPR_INC3],[TAPR_INC4],[TAPR_INC5],[TAPR_DEC1],[TAPR_DEC2],[TAPR_DEC3],[TAPR_DEC4],[TAPR_DEC5],[TAPR_ACCESS_CARD_TYPE],[TAPR_ACCESS_CARD_NUM],[TAPR_CONNECTION_REQUIRED],[TAPR_ALLOW_REMOTE_EXTENSION],[TAPR_TARIFF_ENGINE],[TAPR_BNF_ID],[TAPR_ALLOW_VOUCHERS],[TAPR_VOUCHER_ASSIGN_TYPE],[TAPR_VOUCHER_ASSIGN_VALUE],[TAPR_PERMITTED_ASSIGNED_TARIFFS],[TAPR_AUTO_ASSIGNED_TARIFFS],[TAPR_COMPUTESTEPS_TIMEAMOUNT],[TAPR_COMPUTESTEPS_OFFSET],[TAPR_ALLOW_STEP_OVERPAY],[TAPR_ALLOWED_PAYMENT_METHODS])\n" +
    "VALUES('"+inputField.ext_permit_name+"',"+inputField.TAR_ID+",null,null,"+inputField.grpt_id+",'2019-03-01 00:00:00.000','2050-01-01 00:00:00.000',null,null,null,null,null,null,1,1,1,0,"+inputField.TCS_ID+",1,0,0,5,10,30,60,120,5,10,30,60,120,null,null,null,0,12,null,0,null,null,null,null,null,null,0,null)";

    let gta = "INSERT INTO [dbo].[GROUPS_TYPES_ASSIGNATIONS]([GTA_DESCRIPTION],[GTA_GRP_ID],[GTA_GRPT_ID],[GTA_INI_APPLY_DATE],[GTA_END_APPLY_DATE],[GTA_MAIN])\n"+
    "VALUES('"+inputField.ext_permit_name+"',"+inputField.grp_id+","+inputField.grpt_id+",'2020-05-01 00:00:00.000','2050-01-01 00:00:00.000',0)"
    
    let queryObj = {
      grpt: grpt,
      tarif: tarif, 
      monthly_permit_definition: monthly_permit_definition, 
      tariff_constraint_set: tariff_constraint_set,
      tariff_application_rules: tariff_application_rules,
      gta: gta,
    }
    
    return queryObj;
  }

  newExistedPermit(inputField){
    let gta = "INSERT INTO [dbo].[GROUPS_TYPES_ASSIGNATIONS]([GTA_DESCRIPTION],[GTA_GRP_ID],[GTA_GRPT_ID],[GTA_INI_APPLY_DATE],[GTA_END_APPLY_DATE],[GTA_MAIN])\n"+
    "VALUES('"+inputField.ext_permit_name+"',"+inputField.grp_id+","+inputField.grpt_id+",'2020-05-01 00:00:00.000','2050-01-01 00:00:00.000',0)"
    
    let queryObj = {
      gta: gta,
    }
    
    return queryObj;
  }

}
let permitQueryGenerator = new PermitQueryGenerator();
export default permitQueryGenerator;

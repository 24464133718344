import React, {useState, useEffect} from "react";
import {Box, Stepper, Step, StepLabel, Button, Typography, Paper} from '@mui/material';
import NewPermitForm from "./NewPermitForm"
import Header from "../../Common/Header/Header";
import Sidenav from "../../Common/Sidenav/Sidenav";
import commonService from "../../../services/common-service";
import Spinner from "../../../shared/Spinner";
import ConfirmDiallog from "../../../shared/ConfirmDiallog";
import SnackAlert from "../../../shared/SnackAlert";
import permitQueryGenerator from "../../../helper/permit_query_generator";
import NewPermitQuery from "./NewPermitQuery";
import NewPermitIntegraQuery from "./NewPermitIntegraQuery";
import { useParams } from "react-router-dom";

const steps = ['Add Monthly Permit Detail', 'Insert to dbPIC', 'Insert to IntegraMobile'];
export default function NewMonthlyPermit() {
    const {domain} = useParams();
    const [activeStep, setActiveStep] = React.useState(0);
    const [alert, setAlert] = React.useState(false);
    const [openDialog, setOpenDialog] = React.useState(false);
    const [msg, setMsg] = React.useState('');
    const [severity, setSeverity] = React.useState('');
    const [installations, setInstallations] = useState([]);
    const [groups, setGroups] = useState([]);
    const [inputField, setInputField] = useState({});
    const [inputQuery, setInputQuery] = useState({});
    const [inputIntegraQuery, setInputIntegraQuery] = useState({});
    const [selectedIns, setSelectedIns] = useState(null);
    const [selectedGrp, setSelectedGrp] = useState(null);
    const [spinner, setSpinner] = React.useState(false);
    
    const handleChange=(e) => {
        setInputField({...inputField, [e.target.name]: e.target.value});
    }

    const handleQueryChange=(e) => {
        setInputQuery({...inputQuery, [e.target.name]: e.target.value});
    }

    const handleIntegraQueryChange=(e) => {
        setInputIntegraQuery({...inputIntegraQuery, [e.target.name]: e.target.value});
    }

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      };
    
    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const getInstalltions=async() => {
        setSpinner(true);
        const user = JSON.parse(sessionStorage.getItem('userLogged'));
        let body = {domain: domain};
        if(user.email == "cwpadmin"){
            body.ins_description = 'cwp'
        }
        const res = await commonService.get_installtions(body);
        setInstallations(res.data);
        setSpinner(false);
    }

    const get_last_ids=async() => {
        setSpinner(true);
        const res = await commonService.get_last_ids({domain: domain});
        let obj = {
            TAR_ID : parseInt(res.data[1].tar_id)+1,
            TCS_ID : parseInt(res.data[6].tcs_id)+1,
            integra_lit_id : parseInt(res.data[8].integra_lit_id)+1,
            integra_tar_id : parseInt(res.data[10].integra_tar_id)+1
        }
        setInputField(obj);
        setSpinner(false);
    }

    const onInsSelect=async(newValue) => {
        setSpinner(true);
        setSelectedIns(newValue);
        setSelectedGrp(null);
        const res = await commonService.get_groups_by_ins_id({ins_id: newValue.ins_id, domain: domain});
        inputField['ins_id'] = newValue.ins_id;
        setGroups(res.data);
        setSpinner(false);
    }

    const onGrpSelect=async(newValue) => {
        setSpinner(true);
        setSelectedGrp(newValue);
        const res = await commonService.get_integra_group_by_dbPIC_grp_id({grp_id: newValue.grp_id, domain: domain});
        inputField['integra_grp_id'] = res.data[0].grp_id;
        inputField['integra_ins_id'] = res.data[0].grp_ins_id;
        inputField['grp_id'] = newValue.grp_id;
        setSpinner(false);
    }

    const handleSubmit=async(e) => {
        e.preventDefault();
        if(parseInt(inputField['full_month_rate']) < parseInt(inputField['half_month_rate'])){
            setAlert(true);
            setMsg("Full month rate must be greater than half month rate")
            setSeverity('error');
            return;
        }
        let query = await permitQueryGenerator.newMonthlyPermit(inputField);
        setInputQuery(query.queryObj);
        setInputIntegraQuery(query.integraObj)
        handleNext();
    }

    const insertToDB = async () =>{
        setSpinner(true);
        Object.keys(inputQuery).map(function(key, index) {
            inputQuery[key] = inputQuery[key].replace(/\n/g, '');
          });
        inputQuery['domain'] = domain
        const res = await commonService.insert_to_db(inputQuery);
        if(res.data == true){
            setMsg("Monthly Permit Added Successfully in dbPIC");
            setSeverity('success');
            handleNext();
        }else{
            setMsg(res.data.map(x=>{return x}).join('\n\n'))
            setSeverity('error');
        }
        setAlert(true);
        setSpinner(false);
    }

    const insertToIntegra = async () =>{
        setSpinner(true);
        Object.keys(inputIntegraQuery).map(function(key, index) {
            inputIntegraQuery[key] = inputIntegraQuery[key].replace(/\n/g, '');
          });
        inputIntegraQuery['domain'] = domain;
        const res = await commonService.insert_to_integra(inputIntegraQuery);
        if(res.data == true){
            setMsg("Monthly Permit Added Successfully in Integramobile");
            setSeverity('success');
            // handleNext();
        }else{
            setMsg(res.data.map(x=>{return x}).join('\n\n'))
            setSeverity('error');
        }
        setAlert(true);
        setSpinner(false);
    }

    useEffect(()=>{
        getInstalltions();
        get_last_ids();
    },[])

    return (
        <div>
            <div>
            <Header />
            <div className="main-container my-3">
                <div className="row m-2">
                    <div className="col-lg-2 col-xl-2 d-none d-lg-block d-xl-block">
                        <Sidenav/>
                    </div>
                    <div className="col-lg-10 col-xl-10">
                        <Paper elevation={2} sx={{ width: '100%', pt: 2}}>
                            <Stepper activeStep={activeStep}>
                                {steps.map((label, index) => {
                                const stepProps = {};
                                const labelProps = {};
                                return (
                                    <Step key={label} {...stepProps}>
                                    <StepLabel {...labelProps}>{label}</StepLabel>
                                    </Step>
                                );
                                })}
                            </Stepper>
                            {activeStep === steps.length ? (
                                <React.Fragment>
                                <Typography sx={{ mt: 2, mb: 1 }}>
                                    All steps completed - you&apos;re finished
                                </Typography>
                                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                                    <Box sx={{ flex: '1 1 auto' }} />
                                    <Button >Reset</Button>
                                </Box>
                                </React.Fragment>
                            ) : (
                                <React.Fragment>
                                    {activeStep === 0 && 
                                        <>
                                        <NewPermitForm
                                                installations = {installations}
                                                groups = {groups}
                                                selectedIns = {selectedIns}
                                                selectedGrp = {selectedGrp}
                                                inputField = {inputField}

                                                onInsSelect = {(e)=>onInsSelect(e)}
                                                onGrpSelect = {(e)=>onGrpSelect(e)}
                                                handleChange = {(e)=>handleChange(e)}
                                                handleSubmit = {(e)=>handleSubmit(e)}
                                        /> 
                                       </>
                                    }
                                    {activeStep === 1 && 
                                       <>
                                            <NewPermitQuery
                                                inputQuery = {inputQuery}

                                                handleQueryChange = {(e)=>handleQueryChange(e)}
                                                insertToDB = {(e)=>insertToDB(e)}
                                                handleBack = {()=>handleBack()}
                                            />
                                       </>
                                    }
                                    {activeStep === 2 && 
                                       <>
                                            <NewPermitIntegraQuery
                                                inputIntegraQuery = {inputIntegraQuery}

                                                handleIntegraQueryChange = {(e)=>handleIntegraQueryChange(e)}
                                                insertToIntegra = {(e)=>insertToIntegra(e)}
                                                handleBack = {()=>handleBack()}
                                            />
                                       </> 
                                    }
                                </React.Fragment>
                            )}
                        </Paper>
                    </div>
                </div>
            </div>
            
            <Spinner
                spinner = {spinner}
            />
            <SnackAlert 
                msg={msg}
                alert={alert}
                severity={severity}

                closeAlert={()=>setAlert(false)}
            />
            <ConfirmDiallog
                openDialog = {openDialog}

                closeDialog = {()=>setOpenDialog(false)}
            />
        </div>
            <Spinner
                spinner = {spinner}
            />
        </div>
    );
}

const paths = {
  login: '/',
  new_rate: '/new_rate',
  existing_rate: '/existing_rate',
  external_permit: '/external_permit',
  select_profile: '/select_profile',
  select_rate: '/select_rate',
  button_to_wheel: '/button_to_wheel',
  monthly_permit: '/monthly_permit',
  new_monthly_permit: '/new_monthly_permit',
  update_monthly_permit: '/update_monthly_permit',
  disable_zone: '/disable_zone',
  update_rate: '/update_rate',
  enable_zone: '/enable_zone',
  select_domain: '/select_domain',
  add_door_code: '/add_door_code',
}

const router = {
  login: '/',
  new_rate: paths.select_domain+paths.select_profile+paths.update_rate+paths.new_rate,
  existing_rate: '/existing_rate',
  external_permit: paths.select_domain+paths.select_profile+paths.monthly_permit+paths.external_permit,
  select_profile: paths.select_domain+paths.select_profile,
  select_rate: paths.select_domain+paths.select_profile+paths.select_rate,
  button_to_wheel: paths.select_domain+paths.select_profile+paths.button_to_wheel,
  monthly_permit: paths.select_domain+paths.select_profile+paths.monthly_permit,
  new_monthly_permit: paths.select_domain+paths.select_profile+paths.monthly_permit+paths.new_monthly_permit,
  update_monthly_permit: paths.select_domain+paths.select_profile+paths.monthly_permit+paths.update_monthly_permit,
  disable_zone: paths.select_domain+paths.select_profile+paths.disable_zone,
  update_rate: paths.select_domain+paths.select_profile+paths.update_rate,
  enable_zone: paths.select_domain+paths.select_profile+paths.enable_zone,
  select_domain: paths.select_domain,
  add_door_code: paths.select_domain+paths.select_profile+paths.add_door_code,
};

export default router;

import React from "react";
import {Button, Paper, Box, Autocomplete, TextField, Typography} from '@mui/material';
export default function UpdatePermitForm(props) {

    return (
        <Paper elevation={0} sx={{ width: '100%', p: 3}}>
            <Typography variant="body1" className="font-bold">Update Monthly Permit</Typography>
            <form onSubmit={props.handleSubmit}>
                <div className="row">
                    <div className='col-lg-6'>
                        <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            options={props.installations}
                            getOptionLabel={(option) => option.ins_description}
                            name="selectedIns"
                            value={props.selectedIns}
                            onChange={(event, newValue)=>props.onInsSelect(newValue)}
                            renderInput={(params) =>
                            <TextField 
                                {...params} id="standard-basic" 
                                label="City" variant="standard" 
                                className="width-90" 
                                required
                            />}
                    />
                    </div>
                    <div className='col-lg-6'>
                        <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            options={props.groups}
                            getOptionLabel={(option) => option.grp_description}
                            name="selectedIns"
                            value={props.selectedGrp}
                            onChange={(event, newValue)=>props.onGrpSelect(newValue)}
                            renderInput={(params) =>
                            <TextField 
                                {...params} id="standard-basic" 
                                label="Zone" variant="standard" 
                                className="width-90" 
                                required
                            />}
                    />
                    </div>
                    <div className='col-lg-6'>
                        <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            options={props.monthlyPermits}
                            getOptionLabel={(option) => option.tar_description}
                            value={props.selectedPermit}
                            onChange={(event, newValue)=>props.onPermitSelect(newValue)}
                            renderInput={(params) =>
                            <TextField 
                                {...params} id="standard-basic" 
                                label="Select Permit" variant="standard" 
                                className="width-90" 
                                required
                            />}
                    />
                    </div>
                    <div className='col-md-6'>
                        <TextField
                            id="standard-basic" type="number"
                            label="Full Month Rate(in cents)" variant="standard" 
                            name="full_month_rate" value={props.inputField['full_month_rate']}
                            className="width-90" onChange={props.handleChange}
                            InputLabelProps={{ shrink: true }} required/>
                    </div>
                    <div className='col-md-6'>
                        <TextField
                            id="standard-basic" type="number"
                            label="Half Month Rate(in cents)" variant="standard" 
                            name="half_month_rate" value={props.inputField['half_month_rate']}
                            className="width-90" onChange={props.handleChange}
                            InputLabelProps={{ shrink: true }} required/>
                    </div>
                </div>
                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                    <Box sx={{ flex: '1 1 auto' }} />
                    <Button type="button" className="me-2" onClick={()=>{window.location.reload()}}>
                        Reset
                    </Button>
                    <Button type="submit" className="btn btn-blue px-3 fit-content">
                        Update permit
                    </Button>
                </Box>
            </form>
        </Paper>
    );
}

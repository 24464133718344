import React, {useState, useEffect} from "react";
import {Paper} from '@mui/material';
import Header from "../../Common/Header/Header";
import Sidenav from "../../Common/Sidenav/Sidenav";
import commonService from "../../../services/common-service";
import Spinner from "../../../shared/Spinner";
import SnackAlert from "../../../shared/SnackAlert";
import UpdatePermitForm from "./UpdatePermitForm";
import { useParams } from "react-router-dom";

export default function UpdateMonthlyPermit() {
    const {domain} = useParams();
    const [alert, setAlert] = React.useState(false);
    const [msg, setMsg] = React.useState('');
    const [severity, setSeverity] = React.useState('');
    const [installations, setInstallations] = useState([]);
    const [groups, setGroups] = useState([]);
    const [monthlyPermits, setMonthlyPermits] = useState([]);
    const [inputField, setInputField] = useState({});
    const [selectedIns, setSelectedIns] = useState(null);
    const [selectedGrp, setSelectedGrp] = useState(null);
    const [selectedPermit, setSelectedPermit] = useState(null);
    const [spinner, setSpinner] = React.useState(false);
    
    const handleChange=(e) => {
        setInputField({...inputField, [e.target.name]: e.target.value});
    }

    const getInstalltions=async() => {
        setSpinner(true);
        const user = JSON.parse(sessionStorage.getItem('userLogged'));
        let body = {domain: domain};
        if(user.email == "cwpadmin"){
            body.ins_description = 'cwp'
        }
        const res = await commonService.get_installtions(body);
        setInstallations(res.data);
        setSpinner(false);
    }

    const onInsSelect=async(newValue) => {
        setSpinner(true);
        setSelectedIns(newValue);
        const res = await commonService.get_monthly_permits_groups({ins_id: newValue.ins_id, domain: domain});
        if(res.data.length == 0){
            setAlert(true);
            setMsg("This city doesn't have any zone with monthly permits");
            setSeverity('warning');
        }
        setGroups(res.data);
        setSelectedGrp(null);
        setSelectedPermit(null);
        inputField['full_month_rate'] = '0'
        inputField['half_month_rate'] = '0'
        setSpinner(false);
    }

    const onGrpSelect=async(newValue) => {
        setSpinner(true);
        setSelectedGrp(newValue);
        const res = await commonService.get_monthly_permit({grp_id: newValue.grp_id, domain: domain});
        if(res.data.length == 0){
            setAlert(true);
            setMsg("This zone only contains external permits");
            setSeverity('warning');
        }
        setMonthlyPermits(res.data);
        setSelectedPermit(null);
        inputField['full_month_rate'] = '0'
        inputField['half_month_rate'] = '0'
        setSpinner(false);
    }

    const onPermitSelect=async(newValue) => {
        setSelectedPermit(newValue);
        inputField['full_month_rate'] = newValue.MPF_AMOUNT_MONTH
        inputField['half_month_rate'] = newValue.MPF_AMOUNT_HALF_MONTH
    }

    const handleSubmit=async(e) => {
        e.preventDefault();
        if(parseInt(inputField['full_month_rate']) < parseInt(inputField['half_month_rate'])){
            setAlert(true);
            setMsg("Full month rate must be greater than half month rate")
            setSeverity('error');
            return;
        }
        setSpinner(true);
        inputField['tar_id'] = selectedPermit.TAPR_TAR_ID
        inputField['domain'] = domain;
        const res = await commonService.update_monthly_permit(inputField);
        if(res.data == true){
            setAlert(true);
            setMsg("Monthly Permit updated successfully");
            setSeverity('success');
        }else{
            setAlert(true);
            setMsg("Error occured. Please try again");
            setSeverity('error');
        }
        setSpinner(false);
    }

    useEffect(()=>{
        getInstalltions();
    },[])

    return (
        <div>
            <Header />
            <div className="main-container my-3">
                <div className="row m-2">
                    <div className="col-lg-2 col-xl-2 d-none d-lg-block d-xl-block">
                        <Sidenav/>
                    </div>
                    <div className="col-lg-10 col-xl-10">
                        <Paper elevation={2} sx={{ width: '100%', pt: 2}}> 
                            <UpdatePermitForm
                                inputField  = {inputField}
                                installations = {installations}
                                groups = {groups}
                                selectedIns = {selectedIns}
                                selectedGrp = {selectedGrp}
                                monthlyPermits = {monthlyPermits}
                                selectedPermit = {selectedPermit}

                                handleSubmit = {(e)=>handleSubmit(e)}
                                onInsSelect = {(e)=>onInsSelect(e)}
                                onGrpSelect = {(e)=>onGrpSelect(e)}
                                onPermitSelect = {(e)=>onPermitSelect(e)}
                                handleChange = {(e)=>handleChange(e)}
                            />
                        </Paper>
                    </div>
                </div>
            </div>
            <Spinner
                spinner = {spinner}
            />
            <SnackAlert 
                msg={msg}
                alert={alert}
                severity={severity}

                closeAlert={()=>setAlert(false)}
            />
        </div>
    );
}

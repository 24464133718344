import * as React from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import FullDayRate from './FullDayRate';
import ThreeContinousRate from './ThreeContinousRate';
import OverlappedRates from './OverlappedRates';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function RATE_STEPS(props) {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    // if(newValue == 0){
    //   let obj = {
    //     grp_id : parseInt(props.lastIds[0].grp_id)+1,
    //     TAR_ID : parseInt(props.lastIds[1].tar_id)+1,
    //     RAT_ID : parseInt(props.lastIds[2].rat_id)+1,
    //     RBS_ID : parseInt(props.lastIds[3].rbs_id)+1,
    //     DAT_ID : parseInt(props.lastIds[4].dat_id)+1,
    //     DAH_ID : parseInt(props.lastIds[5].dah_id)+1,
    //     TCS_ID : parseInt(props.lastIds[6].tcs_id)+1,
    //     TCE_ID : parseInt(props.lastIds[7].tce_id)+1,
    //   }
    //   props.setAvailableId(obj);
    // }
    setAvailableId();
  };

  const setAvailableId = ()=>{
    let obj;
    if(value == 0){
      obj = {
        grp_id : parseInt(props.lastIds[0].grp_id)+1,
        TAR_ID : parseInt(props.lastIds[1].tar_id)+1,
        RAT_ID : parseInt(props.lastIds[2].rat_id)+1,
        RBS_ID : parseInt(props.lastIds[3].rbs_id)+1,
        DAT_ID : parseInt(props.lastIds[4].dat_id)+1,
        DAH_ID : parseInt(props.lastIds[5].dah_id)+1,
        TCS_ID : parseInt(props.lastIds[6].tcs_id)+1,
        TCE_ID : parseInt(props.lastIds[7].tce_id)+1,
      }
    }else if(value == 1){
      obj = {
        grp_id : parseInt(props.lastIds[0].grp_id)+1,
        TAR_ID : parseInt(props.lastIds[1].tar_id)+1,
        RAT_ID : parseInt(props.lastIds[2].rat_id)+1,
        RBS_ID : parseInt(props.lastIds[3].rbs_id)+1,
        DAT_ID : parseInt(props.lastIds[4].dat_id)+1,
        DAH_ID : parseInt(props.lastIds[5].dah_id)+1,
        TCS_ID : parseInt(props.lastIds[6].tcs_id)+1,
        TCE_ID : parseInt(props.lastIds[7].tce_id)+1,
      }
    }
    props.setAvailableId(obj);
  }

  // React.useEffect(()=>{
  //   setAvailableId();
  // },[])
console.log(props.ticketInsId,props.integraInsId);
  return (
    <Box sx={{ width: '100%', p: 3 }}>
      <Typography variant="h6" className="font-bold">Add Rates</Typography>
      {props.id == '24_hr_rate' && <FullDayRate
          inputRate = {props.inputRate}
          inputGroup = {props.inputGroup}
          integraInsId = {props.integraInsId}
          ticketInsId = {props.ticketInsId}

          handleRateChange = {props.handleRateChange}
          handleRateChecked = {props.handleRateChecked}
          setInputRate = {props.setInputRate}
          setRateQuery = {props.setRateQuery}
          setQueryObj = {props.setQueryObj}
          setIntegraQueryObj = {props.setIntegraQueryObj}
          setTicketQueryObj = {props.setTicketQueryObj}
          generateQuery = {props.generateQuery}
          setGroupQuery = {props.setGroupQuery}
          setIntegraGroupQuery = {props.setIntegraGroupQuery}
          setTicketGroupQuery = {props.setTicketGroupQuery}
          handleBack = {props.handleBack}
      />}
      {props.id == 'day_night_weekend_rate' && <ThreeContinousRate
          inputRate = {props.inputRate}
          inputGroup = {props.inputGroup}
          integraInsId = {props.integraInsId}
          ticketInsId = {props.ticketInsId}

          handleRateChange = {props.handleRateChange}
          handleRateChecked = {props.handleRateChecked}
          setInputRate = {props.setInputRate}
          setRateQuery = {props.setRateQuery}
          setQueryObj = {props.setQueryObj}
          setIntegraQueryObj = {props.setIntegraQueryObj}
          setTicketQueryObj = {props.setTicketQueryObj}
          generateQuery = {props.generateQuery}
          setGroupQuery = {props.setGroupQuery}
          setIntegraGroupQuery = {props.setIntegraGroupQuery}
          setTicketGroupQuery = {props.setTicketGroupQuery}
          handleBack = {props.handleBack}
      />}
      {props.id == 'overlapped_rate' && <OverlappedRates
          inputRate = {props.inputRate}
          inputGroup = {props.inputGroup}
          integraInsId = {props.integraInsId}
          ticketInsId = {props.ticketInsId}

          handleRateChange = {props.handleRateChange}
          setInputRate = {props.setInputRate}
          setRateQuery = {props.setRateQuery}
          setQueryObj = {props.setQueryObj}
          setIntegraQueryObj = {props.setIntegraQueryObj}
          setTicketQueryObj = {props.setTicketQueryObj}
          generateQuery = {props.generateQuery}
          setGroupQuery = {props.setGroupQuery}
          setIntegraGroupQuery = {props.setIntegraGroupQuery}
          setTicketGroupQuery = {props.setTicketGroupQuery}
          handleBack = {props.handleBack}
      />}
      {/* <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="24 Hour Rate" {...a11yProps(0)} />
          <Tab label="Three Continous Rates" {...a11yProps(1)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
      
      </TabPanel>
      <TabPanel value={value} index={1}>
        
      </TabPanel>
      <TabPanel value={value} index={2}>
        Item Three
      </TabPanel> */}
    </Box>
  );
}

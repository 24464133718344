import React from "react";
import { 
    Button, Typography, Paper, TextareaAutosize, Box
} from "@mui/material";

export default function NewPermitQuery(props) {
    
    return (
        <div className="row">
            <Paper elevation={0} sx={{whiteSpace: 'pre-wrap', mt: 3, p: 2}}>
                <div className="row">
                    <Typography variant="h5" className="font-bold">dbPIC</Typography>
                    <div className="col-md-12">
                        <Typography variant="body1" className="font-bold">Tariff</Typography>
                        <TextareaAutosize
                            aria-label="minimum height"
                            minRows={2}
                            name="tarif"
                            style={{ width: "100%", marginTop: '13px !important', border: 0, background: '#f0f0f0', padding: '15px'}}
                            value={props.inputQuery['tarif']}
                            onChange={(e)=>props.handleQueryChange(e)}
                            ref={props.textAreaRef}
                        />
                    </div>
                    <div className="col-md-12">
                        <Typography variant="body1" className="font-bold">Tariff Constraint Set</Typography>
                        <TextareaAutosize
                            aria-label="minimum height"
                            minRows={2}
                            name="tariff_constraint_set"
                            style={{ width: "100%", marginTop: '13px !important', border: 0, background: '#f0f0f0', padding: '15px'}}
                            value={props.inputQuery['tariff_constraint_set']}
                            onChange={(e)=>props.handleQueryChange(e)}
                            ref={props.textAreaRef}
                        />
                    </div>
                    <div className="col-md-12">
                        <Typography variant="body1" className="font-bold">Tariff Application Rules</Typography>
                        <TextareaAutosize
                            aria-label="minimum height"
                            minRows={2}
                            name="tariff_application_rules"
                            style={{ width: "100%", marginTop: '13px !important', border: 0, background: '#f0f0f0', padding: '15px'}}
                            value={props.inputQuery['tariff_application_rules']}
                            onChange={(e)=>props.handleQueryChange(e)}
                            ref={props.textAreaRef}
                        />
                    </div>
                </div>
            </Paper>
            <Box sx={{ display: 'flex', flexDirection: 'row', py: 2 }}>
                <Button
                    color="inherit"
                    onClick={()=>props.handleBack()}
                    sx={{ mr: 1 }}
                    >
                    Back
                </Button>
                <Box sx={{ flex: '1 1 auto' }} />
                <Button type="button" className="me-2" onClick={()=>{props.handleNext();}}>
                    Next
                </Button>
                {/* <Button type="button" className="btn btn-blue px-3 me-2 fit-content" onClick={()=>{TextFile()}}>
                    Download Script
                </Button> */}
                <Button type="button" className="btn btn-blue px-3 fit-content" onClick={props.insertToDB}>
                    Insert to dbPIC
                </Button>
            </Box>
        </div>
    );
}
